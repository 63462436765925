import React from "react";
import ErrorBoundary from "react-native-error-boundary";
import { useTranslation } from "react-i18next";
import { Button, NativeSyntheticEvent, NativeTouchEvent } from "react-native";
import { useTheme } from "@react-navigation/native";
import { View, Text } from "./Themed";

const ErrorFallback = (props: {
  error: Error;
  resetError: (ev: NativeSyntheticEvent<NativeTouchEvent>) => void;
}) => {
  return (
    <View style={{ margin: 20 }}>
      <Text>Something happened!</Text>
      <Text>{props.error.toString()}</Text>
      <View style={{ marginTop: 20 }}>
        <Button onPress={props.resetError} title={"Try again"} />
      </View>
    </View>
  );
};

export default function ({ children }: { children: React.ReactElement }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
}
