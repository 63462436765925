import { createContext } from "react";
export interface User {
    name: string;
    picture: string;
}

export const UserContext = createContext<{
    user: User | null;
    token: string | null;
    setToken: (token: string | null) => void;
}>({
    user: null,
    token: null,
    setToken: (_token: string | null) => {},
  });
  
