import { FontAwesome } from "@expo/vector-icons";
import { startOfDay } from "date-fns";
import React from "react";
import { StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia
} from "rn-placeholder";
import { useBilanPreviewQuery } from "../../../generated/graphql";
import { View } from "../../Themed";

export default function StatsPreview() {
  const { loading, data } = useBilanPreviewQuery({
    variables: {
      date: startOfDay(new Date()).toISOString(),
    },
  });
  const theme = useTheme();

  if (loading) {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        Right={PlaceholderMedia}
      >
        <PlaceholderLine width={80} />
        <PlaceholderLine />
        <PlaceholderLine width={30} />
      </Placeholder>
    );
  }

  return (
    <View>
      <FontAwesome
        name="line-chart"
        size={80}
        style={{ color: theme.colors.text }}
      />
    </View>
  );
}
