import { StatusBar } from "expo-status-bar";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet } from "react-native";
import AccountsList from "../components/Accounts/List";
import { View } from "../components/Themed";
import TranslatedText from "../components/TranslatedText";

export default function AccountsListScreen() {
  return (
    <View style={styles.container}>
      <TranslatedText style={styles.title}>All accounts</TranslatedText>
      <View
        style={styles.separator}
        lightColor="#eee"
        darkColor="rgba(255,255,255,0.1)"
      />
      <AccountsList style={styles.accountsList} editable={true} />
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  accountsList: {
    width: "100%",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
