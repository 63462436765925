import { StatusBar } from "expo-status-bar";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet } from "react-native";
import AccountsList from "../components/Accounts/List";
import TransactionsList from "../components/Accounts/TransactionsList";
import ErrorBoundary from "../components/ErrorBoundary";
import { Text, View } from "../components/Themed";

export default function TransactionsListScreen() {
  const { t } = useTranslation();
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{t("Transactions")}</Text>
      <View
        style={styles.separator}
        lightColor="#eee"
        darkColor="rgba(255,255,255,0.1)"
      />
      <ErrorBoundary>
        <TransactionsList style={styles.accountsList} />
      </ErrorBoundary>
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  accountsList: {
    width: "100%",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
