import { FontAwesome } from "@expo/vector-icons";
import React, { useState } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import { useTheme } from "react-native-paper";
import { DatePickerModal } from "react-native-paper-dates";
import {
  TransactionFieldsFragment,
  useCreateTransactionMutation,
  useEditTransactionMutation,
  useTransactionValuesForSelectQuery,
} from "../../../../generated/graphql";
import {
  adjustAccountIdIfNeeded,
  adjustContextIdIfNeeded,
  adjustGroupIdIfNeeded,
  adjustPostIdIfNeeded,
  adjustUserIdIfNeeded,
} from "./hooks";
import {
  getAvailableAccounts,
  getAvailableContexts,
  getAvailablePosts,
  getAvailableUsers,
} from "./utils";
import { subYears } from "date-fns";
import Picker from "../../../Picker";

export default function EditTransaction({
  transaction,
  onSave,
  onCancel,
  canEditDate = true,
}: {
  transaction: Partial<TransactionFieldsFragment>;
  onSave: (transaction: TransactionFieldsFragment) => void;
  onCancel: () => void;
  canEditDate?: boolean;
}) {
  const { data: valuesForSelect, loading: loadingValuesForSelect } =
    useTransactionValuesForSelectQuery();

  const [editTransaction, { loading: editing }] = useEditTransactionMutation();
  const [createTransaction, { loading: creating }] =
    useCreateTransactionMutation();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { control, getValues, setValue } = useForm({
    defaultValues: {
      date: transaction.date,
      groupId: transaction.group?.id,
      userId: transaction.user?.id,
      accountId: transaction.account?.id,
      contextId: transaction.context?.id,
      postId: transaction.post?.id,
      checked: transaction.checked,
      amount: String(
        ((transaction.credit || 0) - (transaction.debit || 0)) / 100
      ),
      comment: transaction.comment,
    },
  });
  const [availableUsers, setAvailableUsers] = useState<
    { id: string; firstname: string }[]
  >(getAvailableUsers(valuesForSelect, transaction.group?.id));
  const [availableContexts, setAvailableContexts] = useState<
    { id: string; name: string }[]
  >(getAvailableContexts(valuesForSelect, transaction.group?.id));
  const [availablePosts, setAvailablePosts] = useState<
    { id: string; name: string }[]
  >(
    getAvailablePosts(
      valuesForSelect,
      transaction.group?.id,
      transaction.context?.id
    )
  );
  const [availableAccounts, setAvailableAccounts] = useState<
    { id: string; name: string }[]
  >(
    getAvailableAccounts(
      valuesForSelect,
      transaction.group?.id,
      transaction.user?.id
    )
  );
  adjustGroupIdIfNeeded(valuesForSelect, setValue, getValues("groupId"));
  adjustUserIdIfNeeded(
    valuesForSelect,
    setValue,
    setAvailableUsers,
    getValues("groupId"),
    getValues("userId")
  );
  adjustContextIdIfNeeded(
    valuesForSelect,
    setValue,
    setAvailableContexts,
    getValues("groupId"),
    getValues("contextId")
  );
  adjustAccountIdIfNeeded(
    valuesForSelect,
    setValue,
    setAvailableAccounts,
    getValues("groupId"),
    getValues("userId"),
    getValues("accountId")
  );
  adjustPostIdIfNeeded(
    valuesForSelect,
    setValue,
    setAvailablePosts,
    getValues("groupId"),
    getValues("contextId"),
    getValues("postId")
  );
  const gqlVariables = () => {
    const amount = Number(getValues("amount"));
    const input = {
      date: getValues("date"),
      groupId: getValues("groupId"),
      userId: getValues("userId"),
      credit: Math.round(amount > 0 ? amount * 100 : 0),
      debit: Math.round(amount < 0 ? -amount * 100 : 0),
      accountId: getValues("accountId"),
      comment: getValues("comment"),
      contextId: getValues("contextId"),
      postId: getValues("postId"),
      checked: transaction.checked,
    };
    return input;
  };

  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

  const showDatePicker = () => {
    setDatePickerVisibility(true);
  };

  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };

  const processing = editing || creating;
  let rowBackground = "#add8e6";
  if (processing) {
    rowBackground = theme.colors.accent;
  }
  const amountController = useController({
    control,
    name: "amount",
  });
  const commentController = useController({
    control,
    name: "comment",
  });
  return (
    <View style={{ backgroundColor: rowBackground }}>
      <View style={styles.wrapper}>
        {canEditDate ? (
          <Controller
            name="date"
            control={control}
            render={({ field }) => {
              return (
                <DatePickerModal
                  visible={isDatePickerVisible}
                  locale={i18n.language}
                  mode="single"
                  onDismiss={hideDatePicker}
                  date={new Date(field.value)}
                  onConfirm={({ date }) => {
                    field.onChange(date!.toISOString());
                    hideDatePicker();
                  }}
                />
              );
            }}
          />
        ) : undefined}
        <Pressable onPress={showDatePicker}>
          <View style={styles.date}>
            <Text>
              {new Date(getValues("date")).toLocaleString(i18n.language, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </Text>
          </View>
        </Pressable>
        <View style={styles.groupUser}>
          <Controller
            name="groupId"
            control={control}
            render={({ field }) => {
              return (
                <Picker
                  testID="groupId"
                  value={field.value}
                  items={
                    valuesForSelect?.groups.map((group) => ({
                      value: group!.id,
                      label: group!.name,
                    })) || []
                  }
                  onValueChange={(itemValue) => {
                    field.onChange({ target: { value: itemValue } });
                    setAvailableUsers(
                      getAvailableUsers(valuesForSelect, itemValue)
                    );
                    setAvailableContexts(
                      getAvailableContexts(valuesForSelect, itemValue)
                    );
                  }}
                ></Picker>
              );
            }}
          />
          <Controller
            name="userId"
            control={control}
            render={({ field }) => {
              return (
                <Picker
                  testID="userId"
                  value={field.value}
                  onValueChange={(itemValue) => {
                    field.onChange(itemValue);
                    setAvailableAccounts(
                      getAvailableAccounts(
                        valuesForSelect,
                        getValues("groupId"),
                        itemValue
                      )
                    );
                  }}
                  items={availableUsers.map((user) => ({
                    label: user.firstname,
                    value: user.id,
                  }))}
                ></Picker>
              );
            }}
          />
        </View>
        <View style={styles.amount}>
          <TextInput
            style={{ backgroundColor: "white" }}
            keyboardType="decimal-pad"
            testID="amount"
            value={amountController.field.value}
            onChangeText={(value) => {
              amountController.field.onChange(
                value.replace(",", ".").replace(/a-zA-Z/, "")
              );
            }}
          />
        </View>
        <View style={styles.comment}>
          <TextInput
            style={{ backgroundColor: "white" }}
            value={commentController.field.value}
            onChangeText={(value) => {
              commentController.field.onChange(value);
            }}
          />
        </View>
        <View style={styles.account}>
          <Controller
            name="accountId"
            control={control}
            render={({ field }) => {
              return (
                <Picker
                  value={field.value}
                  onValueChange={(itemValue) => {
                    field.onChange(itemValue);
                  }}
                  items={availableAccounts.map((account) => ({
                    label: account.name,
                    value: account.id,
                  }))}
                ></Picker>
              );
            }}
          />
        </View>
        <View style={styles.contextPost}>
          <Controller
            name="contextId"
            control={control}
            render={({ field }) => {
              return (
                <Picker
                  value={field.value}
                  onValueChange={(itemValue) => {
                    field.onChange(itemValue);
                    setAvailablePosts(
                      getAvailablePosts(
                        valuesForSelect,
                        getValues("groupId"),
                        itemValue
                      )
                    );
                  }}
                  items={availableContexts.map((context) => ({
                    label: context.name,
                    value: context.id,
                  }))}
                ></Picker>
              );
            }}
          />
          <Controller
            name="postId"
            control={control}
            render={({ field }) => {
              return (
                <Picker
                  value={field.value}
                  onValueChange={(itemValue) => {
                    field.onChange(itemValue);
                  }}
                  items={availablePosts.map((post) => ({
                    label: post.name,
                    value: post.id,
                  }))}
                ></Picker>
              );
            }}
          />
        </View>
        <View style={styles.actions}>
          <Pressable
            testID="save-transaction"
            style={styles.actionIcon}
            onPress={async () => {
              if (transaction.id) {
                const newTransaction = await editTransaction({
                  variables: {
                    ...gqlVariables(),
                    id: transaction.id,
                  },
                });
                onSave(newTransaction.data.editTransaction);
              } else {
                const newTransaction = await createTransaction({
                  variables: {
                    ...gqlVariables(),
                  },
                });
                onSave(newTransaction.data.createTransaction);
              }
            }}
          >
            <FontAwesome
              name="floppy-o"
              size={25}
              style={{ color: theme.colors.text }}
            />
          </Pressable>
          {transaction.id ? (
            <Pressable
              style={styles.actionIcon}
              onPress={() => {
                onCancel();
              }}
            >
              <FontAwesome
                name="backward"
                size={25}
                style={{ color: theme.colors.text }}
              />
            </Pressable>
          ) : undefined}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
  },
  date: {
    minWidth: 200,
    padding: 10,
  },
  groupUser: {
    minWidth: 150,
    padding: 10,
  },
  amount: {
    minWidth: 100,
    padding: 10,
  },
  comment: {
    width: 300,
    padding: 10,
  },
  account: {
    minWidth: 150,
    padding: 10,
  },
  contextPost: {
    minWidth: 150,
    padding: 10,
  },
  actions: {
    minWidth: 100,
    padding: 10,
    flex: 1,
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
  actionIcon: {
    padding: 5,
  },
});
