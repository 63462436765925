import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, StyleSheet, View, ViewStyle } from "react-native";
import { useTheme } from "react-native-paper";
import { RefreshControl } from "react-native-web-refresh-control";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia
} from "rn-placeholder";
import {
  TransactionFieldsFragment,
  useTransactionsQuery
} from "../../../generated/graphql";
import { logger } from "../../../services/logger";
import TransactionItem from "./TransactionItem";

export default function TransactionsList({ style }: { style: ViewStyle }) {
  const [search, setSearch] = useState<{
    comment?: string;
    accountIds?: string[];
    userIds?: string[];
    groupIds?: string[];
  }>({});
  const {
    loading,
    data: transactions,
    refetch,
    updateQuery,
  } = useTransactionsQuery({
    variables: search,
  });
  const theme = useTheme();
  const [reloading, setReloading] = useState(false);
  const [currentNewTransaction, setCurrentNewTransaction] = useState<
    Partial<TransactionFieldsFragment>
  >({
    date: new Date().toISOString(),
    checked: true,
  });
  const { t, i18n } = useTranslation();
  const [newTransactions, setNewTransactions] = useState<
    TransactionFieldsFragment[]
  >([]);

  if (loading || !transactions) {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        Right={PlaceholderMedia}
      >
        <PlaceholderLine width={80} />
        <PlaceholderLine />
        <PlaceholderLine width={30} />
      </Placeholder>
    );
  }

  const unshiftNewTransaction = (transaction: TransactionFieldsFragment) => {
    updateQuery((previous) => ({
      ...previous,
      uncheckedTransactions: {
        items: [transaction, ...previous.uncheckedTransactions.items],
      },
    }));
  };

  const allTransactions = [
    currentNewTransaction,
    ...newTransactions,
    ...transactions.uncheckedTransactions.items,
    ...transactions.listTransactions.items,
  ];

  return (
    <>
      <FlatList
        style={style}
        ItemSeparatorComponent={() => {
          return (
            <View
              style={{
                height: 1,
                width: "100%",
                backgroundColor: theme.colors.background,
              }}
            ></View>
          );
        }}
        refreshControl={
          <RefreshControl
            refreshing={reloading}
            onRefresh={() => {
              setReloading(true);
              logger.debug("refresh transactions list");
              refetch().finally(() => {
                setReloading(false);
              });
            }}
          />
        }
        data={allTransactions}
        renderItem={(item) => {
          return (
            <TransactionItem
              transaction={item.item}
              editOnly={false}
              onCreate={(transaction) => {
                unshiftNewTransaction(transaction);
                setCurrentNewTransaction({ ...transaction, id: undefined });
              }}
              onDuplicate={(transaction) => {
                unshiftNewTransaction(transaction);
              }}
            />
          );
        }}
        keyExtractor={(item) => {
          return item.id;
        }}
      ></FlatList>
    </>
  );
}

const styles = StyleSheet.create({
  multiLines: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});
