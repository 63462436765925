import React, { useEffect, useState } from "react";
import { Text, View } from "../components/Themed";
import { useConnectBankMutation } from "../generated/graphql";
import { RootTabScreenProps } from "../types";

export default function AccountingConnect({
  navigation,
  route,
}: {
  navigation: RootTabScreenProps<"accounting.connect">["navigation"];
  route: RootTabScreenProps<"accounting.connect">["route"];
}) {
  const [done, setDone] = useState(false);
  const [connectBankMutation, { loading }] = useConnectBankMutation();
  console.log(route.params);
  useEffect(() => {
    if (route.path) {
      if (!loading && !done) {
        setDone(true);
        connectBankMutation({
          variables: {
            budgetInsideClientCode: route.params.code,
            budgetInsideClientID: route.params.id_connection,
          },
        }).then(() => {
          navigation.push("accounts.list");
        });
      }
    }
  }, [connectBankMutation, navigation, loading, done]);

  return (
    <View>
      <Text>Connexion du compte en cours ...</Text>
    </View>
  );
}
