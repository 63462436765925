import { FontAwesome } from "@expo/vector-icons";
import sortBy from "lodash/sortBy";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, ScrollView, StyleSheet } from "react-native";
import { TextInput, useTheme } from "react-native-paper";
import Svg, { Circle } from "react-native-svg";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";
import {
  useChartConfigsQuery,
  useComputeStatsLazyQuery,
  useEditStatMutation,
  useEditUserStatsMutation,
} from "../../../generated/graphql";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryStack,
  VictoryTheme,
  VictoryTooltip,
} from "../../../services/victory";
import { RootTabScreenProps } from "../../../types";
import Picker from "../../Picker";
import { Text, View } from "../../Themed";
import StatsComposition from "./Composition";

const COLORS = [
  "red",
  "blue",
  "green",
  "orange",
  "purple",
  "pink",
  "yellow",
  "cyan",
  "brown",
  "grey",
];

export default function Stats({
  navigation,
  route,
}: {
  navigation?: RootTabScreenProps<"accounts">["navigation"];
  route: RootTabScreenProps<"accounts">["route"];
}) {
  const [editStat, { loading: editStatLoading }] = useEditStatMutation();
  const [editUserStats, { loading: editUserStatsLoading }] =
    useEditUserStatsMutation();
  const [statName, setStatName] = useState<string>("");
  const [statId, setStatId] = useState<string>("");
  const { loading, data, refetch } = useChartConfigsQuery({});
  const [
    computeStatsLazyQuery,
    { loading: computeStatsLoading, data: computedStats },
  ] = useComputeStatsLazyQuery();
  const [selectedStats, setSelectedStats] = useState<
    { contextId: string; postId: string; id: string }[] | null
  >(null);
  const { t, i18n } = useTranslation();
  function label(
    year: number,
    contextName: string,
    postName: string,
    amount: number
  ) {
    return `${year}\n${contextName}\n${postName}\n${new Intl.NumberFormat(
      i18n.language,
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: "EUR",
      }
    ).format(amount / 100)}\n\n${t("Total")}\n${new Intl.NumberFormat(
      i18n.language,
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: "EUR",
      }
    ).format(totalForYear(year) / 100)}`;
  }
  const theme = useTheme();
  useEffect(() => {
    if (selectedStats) {
      computeStatsLazyQuery({
        variables: {
          input: {
            contexts: selectedStats.map((s) => ({
              contextId: s.contextId,
              postId: s.postId,
            })),
          },
        },
      });
    }
  }, [selectedStats]);
  const { contexts, chartData } = useMemo(() => {
    if (!computedStats) {
      return {};
    }
    const chartData: Record<
      number,
      {
        x: number;
        y: number;
        contextId: string;
        postId: string;
        label: string;
        fill: string;
      }[]
    > = {};
    const contexts: Record<
      string,
      {
        color: string;
        contextName: string;
        contextId: string;
        postName: string;
        postId: string;
        data: {
          x: number;
          y: number;
          label: string;
          contextId: string;
          postId: string;
        }[];
      }
    > = {};
    const allYears: number[] = [];

    computedStats.chartData.contexts.forEach((context) => {
      if (!allYears.includes(context.year)) {
        allYears.push(context.year);
      }
    });
    computedStats.chartData.contexts.forEach((context) => {
      if (!contexts[context.context.id + "-" + context.post.id]) {
        contexts[context.context.id + "-" + context.post.id] = {
          color: COLORS[Object.keys(contexts).length],
          contextName: context.context.name,
          contextId: context.context.id,
          postName: context.post.name,
          postId: context.post.id,
          data: allYears.map((year) => {
            return {
              contextId: context.context.id,
              postId: context.post.id,
              x: year,
              y: 0,
              label: label(
                context.year,
                context.context.id,
                context.post.name,
                0
              ),
              fill: COLORS[Object.keys(contexts).length],
            };
          }),
        };
      }
      const row = contexts[
        context.context.id + "-" + context.post.id
      ].data.find(
        (c) =>
          c.contextId === context.context.id &&
          c.postId === context.post.id &&
          c.x === context.year
      )!;
      row.y = Math.round(context.amount / 100);
      row.label = label(
        context.year,
        context.context.name,
        context.post.name,
        context.amount
      );
    });

    computedStats.chartData.contexts.forEach((context) => {
      if (!chartData[context.year]) {
        chartData[context.year] = Object.values(contexts).map((c) => {
          return {
            contextId: c.contextId,
            postId: c.postId,
            x: context.year,
            y: 0,
            label: label(context.year, c.contextName, c.postName, 0),
            fill: contexts[c.contextId + "-" + c.postId].color,
          };
        });
      }
      const row = chartData[context.year].find(
        (c) =>
          c.contextId === context.context.id && c.postId === context.post.id
      )!;
      row.y = Math.round(context.amount / 100);
      row.label = label(
        context.year,
        context.context.name,
        context.post.name,
        context.amount
      );
    });

    return { contexts, chartData };
  }, [computedStats]);

  if (loading || !data) {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        Right={PlaceholderMedia}
      >
        <PlaceholderLine width={80} />
        <PlaceholderLine />
        <PlaceholderLine width={30} />
      </Placeholder>
    );
  }
  const styles = StyleSheet.create({
    container: {
      width: "100%",
      justifyContent: "center",
      alignContent: "center",
    },
    legend: {
      marginTop: 20,
      flexDirection: "row",
      flexWrap: "wrap",
    },
    selector: {
      margin: 20,
    },
    rows: {
      flexDirection: "row",
      backgroundColor: theme.colors.surface,
    },
    scrollView: {
      width: "100%",
    },
    panel: {
      width: "50%",
    },
    saveRow: {
      flexDirection: "row",
      margin: 20,
    },
    nameInputWrapper: {
      width: "60%",
    },
    saveButtonWrapper: {
      alignSelf: "center",
      marginLeft: 20,
    },
    chart: {
      height: "100%",
    },
    tooltipArea: {
      height: 100,
      width: "100%",
    },
  });

  function totalForYear(year: number) {
    if (!computedStats) {
      return 0;
    }
    return computedStats.chartData.contexts.reduce((acc, item) => {
      if (item.year === year) {
        return acc + item.amount;
      }
      return acc;
    }, 0);
  }

  return (
    <ScrollView style={styles.scrollView}>
      <View style={styles.selector}>
        <Picker
          items={data.currentUser.stats.map((stat) => ({
            label: stat.name,
            value: stat.id,
          }))}
          value={statId}
          onValueChange={(itemValue: string) => {
            if (navigation) {
              navigation.navigate({
                name: "stats",
                params: { statId: itemValue },
              });
            }
            if (data) {
              const selected = data.currentUser.stats.find(
                (c) => c.id === itemValue
              )!;
              setStatName(selected.name);
              setStatId(selected.id);
              setSelectedStats(
                selected.contexts.map((s) => {
                  return {
                    contextId: s.context.id,
                    postId: s.post.id,
                    id: s.id,
                  };
                })
              );
            }
          }}
        ></Picker>
      </View>
      <StatsComposition
        selectedStats={selectedStats}
        onChange={setSelectedStats}
      />
      <View style={styles.saveRow}>
        <View style={styles.nameInputWrapper}>
          <TextInput
            style={{ backgroundColor: "white" }}
            testID="label-input"
            autoComplete="off"
            onChangeText={(text) => {
              setStatName(text);
            }}
            value={statName}
          ></TextInput>
        </View>
        <View style={styles.saveButtonWrapper}>
          <Pressable
            disabled={
              (!statName && !(selectedStats || []).length) ||
              editStatLoading ||
              editUserStatsLoading
            }
            onPress={() => {
              if (statId) {
                editStat({
                  variables: {
                    id: statId,
                    stat: {
                      id: statId,
                      name: statName,
                      contexts: selectedStats || [],
                    },
                  },
                });
              } else {
                editUserStats({
                  variables: {
                    id: data.currentUser.id,
                    input: {
                      stats: [
                        ...data.currentUser.stats.map((stat) => {
                          return {
                            id: stat.id,
                            name: stat.name,
                            contexts: stat.contexts.map((context) => {
                              return {
                                id: context.id,
                                contextId: context.context.id,
                                postId: context.post.id,
                              };
                            }),
                          };
                        }),
                        {
                          contexts: selectedStats || [],
                          id: `${Date.now()}`,
                          name: statName,
                        },
                      ],
                    },
                  },
                });
              }
            }}
          >
            <FontAwesome name="save" size={30} testID="save"></FontAwesome>
          </Pressable>
        </View>
      </View>
      {computeStatsLoading && (
        <View style={styles.chart}>
          <View style={styles.tooltipArea}></View>
          <Placeholder
            Animation={Fade}
            Left={PlaceholderMedia}
            Right={PlaceholderMedia}
          >
            <PlaceholderLine width={80} />
            <PlaceholderLine />
            <PlaceholderLine width={30} />
          </Placeholder>
        </View>
      )}
      {computedStats && contexts && (
        <View style={styles.chart}>
          <View style={styles.tooltipArea}></View>
          <View style={styles.legend}>
            {Object.values(contexts ?? {}).map((context) => {
              return (
                <View
                  style={{ flexDirection: "row" }}
                  key={context.contextId + "-" + context.postId}
                >
                  <Svg style={{ margin: 5 }} height="20" width="20">
                    <Circle fill={context.color} r={10} cx={10} cy={10} />
                  </Svg>
                  <Text
                    style={{ alignSelf: "center", textAlignVertical: "center" }}
                  >
                    {context.contextName + " " + context.postName}
                  </Text>
                </View>
              );
            })}
          </View>
          <VictoryChart
            theme={VictoryTheme.material}
            domainPadding={{ y: 25 }}
            style={{
              background: { fill: theme.colors.surface },
              parent: {
                paddingTop: "50px",
              },
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
          >
            <VictoryStack
              colorScale={Object.values(contexts ?? {}).map((c) => c.color)}
            >
              {Object.values(contexts ?? {}).map((context) => {
                return (
                  <VictoryBar
                    alignment="middle"
                    key={context.contextId + "-" + context.postId}
                    labels={({ datum }) => `${datum.y} €`}
                    labelComponent={<VictoryTooltip />}
                    data={sortBy(
                      context.data.map((a) => {
                        return {
                          label: a.label,
                          x: `${a.x}`,
                          y: a.y,
                        };
                      }),
                      "x"
                    )}
                  ></VictoryBar>
                );
              })}
            </VictoryStack>
            <VictoryAxis dependentAxis />
            <VictoryAxis
              gridComponent={<></>}
              tickLabelComponent={<VictoryLabel angle={-45} textAnchor="end" />}
            />
          </VictoryChart>
        </View>
      )}
    </ScrollView>
  );
}
