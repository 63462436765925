import { useTranslation } from "react-i18next";
import { Text, TextProps } from "./Themed";

export default function Amount(props: TextProps & {
  children: number;
  maximumFractionDigits?: number;
}) {
  const { i18n } = useTranslation();

  return (
    <Text {...props}>
      {new Intl.NumberFormat(i18n.language, {
        minimumFractionDigits: props.maximumFractionDigits ?? 2,
        maximumFractionDigits: props.maximumFractionDigits ?? 2,
        style: "currency",
        currency: "EUR",
      }).format(props.children / 100)}
    </Text>
  );
}
