import { FontAwesome } from "@expo/vector-icons";
import { startOfDay } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";
import {
    Fade,
    Placeholder,
    PlaceholderLine,
    PlaceholderMedia
} from "rn-placeholder";
import { useBilanPreviewQuery } from "../../../generated/graphql";
import Amount from "../../Amount";
import { View } from "../../Themed";
import TranslatedText from "../../TranslatedText";

export default function BilanPreview() {
  const { loading, data } = useBilanPreviewQuery({
    variables: {
      date: startOfDay(new Date()).toISOString(),
    },
  });
  const theme = useTheme();

  if (loading) {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        Right={PlaceholderMedia}
      >
        <PlaceholderLine width={80} />
        <PlaceholderLine />
        <PlaceholderLine width={30} />
      </Placeholder>
    );
  }

  const styles = StyleSheet.create({});

  return (
    <View>
      <FontAwesome
        name="list-ul"
        size={50}
        style={{ color: theme.colors.text }}
      />
      <TranslatedText>Résultat</TranslatedText>
      <Amount style={{fontWeight: 'bold'}} maximumFractionDigits={0}>{data.bilan.total}</Amount>
    </View>
  );
}
