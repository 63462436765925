import { FontAwesome } from "@expo/vector-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, View } from "react-native";
import { Checkbox, useTheme } from "react-native-paper";
import {
  TransactionFieldsFragment,
  useCreateTransactionMutation,
  useDeleteTransactionMutation,
  useEditTransactionMutation,
  useRestoreTransactionMutation,
  useTransactionValuesForSelectQuery
} from "../../../../generated/graphql";
import Amount from "../../../Amount";
import { Text } from "../../../Themed";
import EditTransaction from "../EditTransaction";

export default function TransactionItem({
  transaction,
  editOnly,
  onDuplicate,
  onCreate,
}: {
  transaction: Partial<TransactionFieldsFragment>;
  editOnly: boolean;
  onDuplicate: (newTransaction: TransactionFieldsFragment) => void;
  onCreate: (newTransaction: TransactionFieldsFragment) => void;
}) {
  const { data: valuesForSelect, loading: loadingValuesForSelect } =
    useTransactionValuesForSelectQuery();
  const [editTransaction, { loading: editing }] = useEditTransactionMutation();
  const [createTransaction, { loading: creating }] =
    useCreateTransactionMutation();
  const [deleteTransaction, { loading: deleting }] =
    useDeleteTransactionMutation();
  const [restoreTransaction, { loading: restoring }] =
    useRestoreTransactionMutation();
  const paramTransaction = {
    id: transaction.id,
    date: transaction.date,
    credit: transaction.credit,
    debit: transaction.debit,
    groupId: transaction.group?.id,
    userId: transaction.user?.id,
    postId: transaction.post?.id,
    contextId: transaction.context?.id,
    checked: transaction.checked,
    accountId: transaction.account?.id,
    comment: transaction.comment,
  };
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [edit, setEdit] = useState(!Boolean(transaction.id));

  const processing = editing || creating || deleting || restoring;
  if (edit || editOnly) {
    return (
      <EditTransaction
        transaction={transaction}
        onCancel={() => {
          console.log('cancel')
          setEdit(false);
        }}
        onSave={(newTransaction) => {
          console.log('save', transaction, newTransaction)
          if (!transaction.id) {
            onCreate(newTransaction);
            return;
          } else {
            console.log('onsave no edit')
            setEdit(false);
          }
        }}
      />
    );
  }

  let rowBackground = theme.colors.surface;
  if (processing) {
    rowBackground = theme.colors.accent;
  } else if (transaction.deleted) {
    rowBackground = theme.colors.disabled;
  } else if (!transaction.checked) {
    rowBackground = theme.colors.notification;
  }

  return (
    <Pressable
      onPress={() => {
        setEdit(true);
      }}
    >
      <View style={{ backgroundColor: rowBackground }}>
        <View style={styles.wrapper}>
          <View style={styles.date}>
            <Text>
              {new Date(transaction.date).toLocaleString(i18n.language, {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </Text>
          </View>
          <View style={styles.groupUser}>
            <Text>{transaction.group.name}</Text>
            <Text>{transaction.user?.firstname}</Text>
          </View>
          <View style={styles.amount}>
            <Amount>{transaction.credit - transaction.debit}</Amount>
          </View>
          <View style={styles.comment}>
            <Text>{transaction.comment}</Text>
          </View>
          <View style={styles.account}>
            <Text>{transaction.account.name}</Text>
          </View>
          <View style={styles.contextPost}>
            <Text>{transaction.context.name}</Text>
            <Text>{transaction.post.name}</Text>
          </View>
          <View style={styles.actions}>
            <Checkbox
              disabled={processing}
              onPress={() => {
                editTransaction({
                  variables: {
                    ...paramTransaction,
                    checked: !transaction.checked,
                  },
                });
              }}
              status={transaction.checked ? "checked" : "unchecked"}
            />
            <Pressable
              disabled={processing}
              style={styles.actionIcon}
              onPress={() => {
                createTransaction({
                  variables: {
                    ...paramTransaction,
                  },
                }).then((newTransaction) => {
                  onDuplicate(newTransaction.data.createTransaction);
                });
              }}
            >
              <FontAwesome
                name="copy"
                size={25}
                style={{ color: theme.colors.text }}
              />
            </Pressable>
            {transaction.deleted ? (
              <Pressable
                disabled={processing}
                style={styles.actionIcon}
                onPress={() => {
                  restoreTransaction({
                    variables: {
                      id: transaction.id,
                    },
                  });
                }}
              >
                <FontAwesome
                  name="refresh"
                  size={25}
                  style={{ color: theme.colors.text }}
                />
              </Pressable>
            ) : (
              <Pressable
                disabled={processing}
                style={styles.actionIcon}
                onPress={() => {
                  deleteTransaction({
                    variables: {
                      id: transaction.id,
                    },
                  });
                }}
              >
                <FontAwesome
                  name="trash"
                  size={25}
                  style={{ color: theme.colors.text }}
                />
              </Pressable>
            )}
          </View>
        </View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
  },
  date: {
    minWidth: 200,
    padding: 10,
  },
  groupUser: {
    minWidth: 150,
    padding: 10,
  },
  amount: {
    minWidth: 100,
    padding: 10,
  },
  comment: {
    width: 300,
    padding: 10,
  },
  account: {
    minWidth: 150,
    padding: 10,
  },
  contextPost: {
    minWidth: 150,
    padding: 10,
  },
  actions: {
    minWidth: 100,
    padding: 10,
    flex: 1,
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
  actionIcon: {
    padding: 5,
  },
});
