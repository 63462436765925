function debug(message?: any, ...optionalParams: any[]) {
    console.log(message, ...optionalParams)
}

function info(message?: any, ...optionalParams: any[]) {
    console.info(message, ...optionalParams)
}

function warn(message?: any, ...optionalParams: any[]) {
    console.warn(message, ...optionalParams)
}

function error(message?: any, ...optionalParams: any[]) {
    console.error(message, ...optionalParams)
}

export const logger = {
    debug,
    info,
    warn,
    error
}