import { useEffect } from "react";
import { TransactionValuesForSelectQuery } from "../../../../generated/graphql";
import {
  getAvailableAccounts,
  getAvailableContexts,
  getAvailablePosts,
  getAvailableUsers,
} from "./utils";

export function adjustGroupIdIfNeeded(
  valuesForSelect: TransactionValuesForSelectQuery | undefined,
  setValue: (field: "groupId", userId?: string) => void,
  groupId?: string
) {
  return useEffect(() => {
    if (!groupId && valuesForSelect && valuesForSelect.groups.length) {
      const groups = valuesForSelect.groups;
      setValue("groupId", groups[0].id);
    }
  }, [valuesForSelect, groupId]);
}

export function adjustAccountIdIfNeeded(
  valuesForSelect: TransactionValuesForSelectQuery | undefined,
  setValue: (field: "accountId", userId?: string) => void,
  setAvailableContext: (
    accounts: TransactionValuesForSelectQuery["groups"][number]["accounts"]
  ) => void,
  groupId?: string,
  userId?: string,
  accountId?: string
) {
  return useEffect(() => {
    const accounts = getAvailableAccounts(valuesForSelect, groupId, userId);
    if (accounts.length) {
      setAvailableContext(accounts);
      const defaultId = accounts.find((u) => u.id).id;
      if (!accountId) {
        setValue("accountId", defaultId);
      }
      if (!accounts.find((u) => u.id === accountId)) {
        setValue("accountId", defaultId);
      }
    }
  }, [valuesForSelect, groupId, userId, accountId]);
}

export function adjustContextIdIfNeeded(
  valuesForSelect: TransactionValuesForSelectQuery | undefined,
  setValue: (field: "contextId", userId?: string) => void,
  setAvailableContext: (
    contexts: TransactionValuesForSelectQuery["groups"][number]["contexts"]
  ) => void,
  groupId?: string,
  contextId?: string
) {
  return useEffect(() => {
    const contexts = getAvailableContexts(valuesForSelect, groupId);
    if (contexts.length) {
      setAvailableContext(contexts);
      const defaultId = contexts.find((u) => u.id).id;
      if (!contextId) {
        setValue("contextId", defaultId);
      }
      if (!contexts.find((u) => u.id === contextId)) {
        setValue("contextId", defaultId);
      }
    }
  }, [valuesForSelect, groupId, contextId]);
}

export function adjustPostIdIfNeeded(
  valuesForSelect: TransactionValuesForSelectQuery | undefined,
  setValue: (field: "postId", userId?: string) => void,
  setAvailablePosts: (
    posts: TransactionValuesForSelectQuery["groups"][number]["contexts"][number]["posts"]
  ) => void,
  groupId?: string,
  contextId?: string,
  postId?: string
) {
  return useEffect(() => {
    const posts = getAvailablePosts(valuesForSelect, groupId, contextId);
    if (posts.length) {
      setAvailablePosts(posts);
      const defaultId = posts.find((u) => u.id).id;
      if (!postId) {
        setValue("postId", defaultId);
      }
      if (!posts.find((u) => u.id === postId)) {
        setValue("postId", defaultId);
      }
    }
  }, [valuesForSelect, groupId, contextId, postId]);
}

export function adjustUserIdIfNeeded(
  valuesForSelect: TransactionValuesForSelectQuery | undefined,
  setValue: (field: "userId", userId?: string) => void,
  setAvailableUsers: (
    users: TransactionValuesForSelectQuery["groups"][number]["users"]
  ) => void,
  groupId?: string,
  userId?: string
) {
  return useEffect(() => {
    const users = getAvailableUsers(valuesForSelect, groupId);
    if (users.length) {
      setAvailableUsers(users);
      const defaultUserId = users.find((u) => u.id).id;
      if (!userId) {
        setValue("userId", defaultUserId);
      }
      if (!users.find((u) => u.id === userId)) {
        setValue("userId", defaultUserId);
      }
    }
  }, [valuesForSelect, groupId, userId]);
}
