import { TransactionValuesForSelectQuery } from "../../../../generated/graphql";

export function getAvailableUsers(
  valuesForSelect: TransactionValuesForSelectQuery | undefined,
  groupId?: string | undefined
) {
  if (!groupId) {
    return [];
  }
  if (!valuesForSelect) {
    return [];
  }
  return valuesForSelect.groups.find((g) => g!.id === groupId)!.users;
}

export function getAvailableContexts(
  valuesForSelect: TransactionValuesForSelectQuery | undefined,
  groupId?: string
) {
  if (!groupId) {
    return [];
  }
  if (!valuesForSelect) {
    return [];
  }
  return valuesForSelect.groups.find((g) => g!.id === groupId)!.contexts;
}

export function getAvailablePosts(
  valuesForSelect: TransactionValuesForSelectQuery | undefined,
  groupId?: string,
  contextId?: string
) {
  if (!groupId) {
    return [];
  }
  if (!contextId) {
    return [];
  }
  if (!valuesForSelect) {
    return [];
  }
  const context = valuesForSelect.groups
    .find((g) => g!.id === groupId)!
    .contexts.find((c) => c.id === contextId);
  if (!context) {
    return [];
  }
  return context.posts;
}

export function getAvailableAccounts(
  valuesForSelect: TransactionValuesForSelectQuery | undefined,
  groupId?: string,
  userId?: string
) {
  if (!valuesForSelect) {
    return [];
  }
  if (!groupId) {
    return [];
  }
  if (!userId) {
    return valuesForSelect.groups.find((g) => g!.id === groupId)!.accounts;
  }
  const user = valuesForSelect.groups
    .find((g) => g!.id === groupId)!
    .users.find((u) => u!.id === userId);
  if (!user) {
    return [];
  }
  return user.accounts;
}
