import { startOfDay } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet } from "react-native";
import { Checkbox, DataTable, useTheme } from "react-native-paper";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";
import { List } from "react-native-paper";
import InnerBalances from "../../Balance/Inner";
import ErrorBoundary from "../../../ErrorBoundary";
import {
  useAddContextAndPostToGroupMutation,
  useAddPostToContextMutation,
  useGetGroupsDetailsQuery,
} from "../../../../generated/graphql";
import { View, Text } from "../../../Themed";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";

export default function GroupsList() {
  const { data: groups, loading: loadingGroups } = useGetGroupsDetailsQuery();
  const [addPostToContext, { loading: addPostToContextLoading }] =
    useAddPostToContextMutation();
  const [
    addContextAndPostToGroup,
    { loading: addContextAndPostToGroupLoading },
  ] = useAddContextAndPostToGroupMutation();
  const processing = addPostToContextLoading || addContextAndPostToGroupLoading;
  const { t } = useTranslation();
  const theme = useTheme();

  const styles = StyleSheet.create({
    container: {
      width: "100%",
      justifyContent: "center",
      alignContent: "center",
    },
    rows: {
      flexDirection: "row",
      backgroundColor: theme.colors.surface,
    },
    scrollView: {
      width: "100%",
    },
    panel: {
      width: "50%",
    },
    cell: {
      width: 150,
    },
  });

  if (loadingGroups || !groups) {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        Right={PlaceholderMedia}
      >
        <PlaceholderLine width={80} />
        <PlaceholderLine />
        <PlaceholderLine width={30} />
      </Placeholder>
    );
  }

  const allContexts = sortBy(
    uniqBy(
      groups.groups.flatMap((group) => group!.contexts),
      "id"
    ),
    "name"
  );
  const nonDedupedContexts = groups.groups.flatMap((group) => group!.contexts);
  const allPosts = sortBy(
    uniqBy(
      nonDedupedContexts.flatMap((c) => c.posts),
      "id"
    ),
    "name"
  );

  return (
    <ScrollView style={styles.scrollView}>
      <View style={styles.container}>
        <ErrorBoundary>
          <InnerBalances></InnerBalances>
        </ErrorBoundary>
      </View>
      {groups.groups.map((group) => {
        return (
          <View>
            <Text>{group!.name}</Text>
            <DataTable>
              <DataTable.Header>
                <DataTable.Title style={styles.cell}>
                  <Text>-</Text>
                </DataTable.Title>
                {allContexts.map((context) => {
                  return (
                    <DataTable.Title style={styles.cell} key={context.id}>
                      <Text>{context!.name}</Text>
                    </DataTable.Title>
                  );
                })}
              </DataTable.Header>
              {allPosts.map((post) => {
                return (
                  <DataTable.Row key={post.id}>
                    <DataTable.Title style={styles.cell} key={post.id}>
                      <Text>{post!.name}</Text>
                    </DataTable.Title>
                    {allContexts.map((context) => {
                      const value = !!group!.contexts
                        .find((c) => c.id === context.id)
                        ?.posts.find((p) => p.id === post.id);

                      return (
                        <DataTable.Cell style={styles.cell} key={context.id}>
                          <Checkbox
                            disabled={value || processing}
                            onPress={() => {
                              if (
                                !group!.contexts.find(
                                  (c) => c.id === context.id
                                )
                              ) {
                                addContextAndPostToGroup({
                                  variables: {
                                    groupId: group!.id,
                                    contextId: context.id,
                                    postId: post.id,
                                    contextName: context.name,
                                    postName: post.name,
                                  },
                                });
                              } else {
                                addPostToContext({
                                  variables: {
                                    groupId: group!.id,
                                    contextId: context.id,
                                    postId: post.id,
                                    name: post.name,
                                  },
                                });
                              }
                            }}
                            status={value ? "checked" : "unchecked"}
                          />
                        </DataTable.Cell>
                      );
                    })}
                  </DataTable.Row>
                );
              })}
            </DataTable>
          </View>
        );
      })}
    </ScrollView>
  );
}
