import { TextInput } from "react-native";
import RNPickerSelect from "react-native-picker-select";

export default function Picker({
  items,
  onValueChange,
  value,
  testID,
  disabled
}: {
  items: {
    value: string;
    label: string;
  }[];
  value: string | undefined;
  testID?: string;
  disabled?: boolean;
  onValueChange: (value: string) => void;
}) {
  if (process.env.NODE_ENV === "test") {
    return (
      <TextInput
        value={value ?? ''}
        testID={testID}
        onChangeText={(text) => {
          onValueChange(text);
        }}
      />
    );
  }
  return (
    <RNPickerSelect
      onValueChange={onValueChange}
      value={value}
      disabled={disabled}
      items={items.map((item) => {
        return {
          label: item.label,
          value: item.value,
        };
      })}
    />
  );
}
