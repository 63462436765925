import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Linking } from "react-native";
import { Button } from "react-native-paper";
import { useBanksOfUserQuery } from "../../../../generated/graphql";
import { logger } from "../../../../services/logger";
import { View, Text } from "../../../Themed";

export default function Connections() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { data: banksOfUser, loading: loadingBanksOfUser } =
    useBanksOfUserQuery();

  if (!banksOfUser || loadingBanksOfUser) {
    return <Text>Loading ...</Text>;
  }

  return (
    <View>
      {banksOfUser.banksOfUser.map((bank) => {
        return (
          <React.Fragment key={bank.id}>
            <Text>{bank.name}</Text>
            {bank.accounts.map((account) => {
              return <div key={account.id}>{account.name}</div>;
            })}
          </React.Fragment>
        );
      })}
      <Button
        loading={loading}
        disabled={loading}
        onPress={async () => {
          setLoading(true)
          logger.debug("new connection");
          const fullUrl = await Linking.getInitialURL();
          if (fullUrl) {
            const baseUrl = new URL(fullUrl);
            await Linking.openURL(
              `https://money-jta-sandbox.biapi.pro/2.0/auth/webview/?client_id=76074460&redirect_uri=${baseUrl.origin}/accounting.connect`
            );
            setLoading(false)
          }
        }}
      >
        <Text>{t("Nouvelle Connection")}</Text>
      </Button>
    </View>
  );
}
