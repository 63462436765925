import sortBy from "lodash/sortBy";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, Text, ViewStyle } from "react-native";
import { Checkbox, DataTable, useTheme } from "react-native-paper";
import { RefreshControl } from "react-native-web-refresh-control";
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from "rn-placeholder";
import {
  useAccountsQuery,
  useComputeAccountsAmountsMutation,
  useCurrentUserAccountsQuery,
} from "../../../generated/graphql";
import { logger } from "../../../services/logger";
import { RootTabScreenProps } from "../../../types";
import Colors from "../../../constants/Colors";
import useColorScheme from "../../../hooks/useColorScheme";
import InnerAccountsList from "./Inner";
import React from "react";
import { Tabs, TabScreen } from "react-native-paper-tabs";
import Connections from "./Connections";

export default function AccountsList({
  style,
  navigation,
  editable = false,
}: { style: ViewStyle; editable?: boolean } & {
  navigation?: RootTabScreenProps<"accounts">["navigation"];
}) {
  const colorScheme = useColorScheme();
  const theme = useTheme();
  const [computeAccountsAmountsMutation, { loading: reloading }] =
    useComputeAccountsAmountsMutation();
  const { t, i18n } = useTranslation();
  const { data, loading } = useAccountsQuery();
  let accounts = sortBy(data?.currentUser?.accounts ?? [], (a) => {
    return a.name.toLowerCase();
  });
  if (!editable) {
    accounts = accounts.filter((a) => Math.round(a.amount / 100));
  }
  if (loading) {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        Right={PlaceholderMedia}
      >
        <PlaceholderLine width={80} />
        <PlaceholderLine />
        <PlaceholderLine width={30} />
      </Placeholder>
    );
  }

  return (
    <ScrollView
      style={style}
      refreshControl={
        <RefreshControl
          refreshing={reloading}
          onRefresh={async () => {
            logger.debug("refresh accounts list");
            computeAccountsAmountsMutation();
          }}
        />
      }
    >
      <Tabs iconPosition="top">
        <TabScreen label={t("Mes comptes")} icon="compass">
          <InnerAccountsList editable={editable} navigation={navigation} />
        </TabScreen>
        <TabScreen label={t("Connections")} icon="airplane">
          <Connections />
        </TabScreen>
      </Tabs>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  positiveRow: {},
  nagetiveRow: {},
});
