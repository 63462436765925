import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { Badge } from "react-native-paper";
import { NetworkError } from "@apollo/client/errors";
import { onError } from "@apollo/client/link/error";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { StatusBar } from "expo-status-bar";
import { GraphQLError } from "graphql";
import jwtDecode from "jwt-decode";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DarkTheme,
  DefaultTheme,
  Provider as PaperProvider,
  Snackbar,
} from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { User, UserContext } from "./context/UserContext";
import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import "./i18n";
import Navigation from "./navigation";
import { logger } from "./services/logger";

const styles = {
  container: {
    flex: 1,
  },
};

export default function App() {
  const { t } = useTranslation();
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [clientErrors, setClientErrors] = useState<
    (GraphQLError | NetworkError)[]
  >([]);
  useEffect(() => {
    async function run() {
      const token = await AsyncStorage.getItem("token");
      if (token) {
        setToken(token);
      }
    }
    run();
  }, []);
  useEffect(() => {
    if (!token) {
      setUser(null);
      AsyncStorage.removeItem("token").catch((err) => {
        logger.error("could not remove token", err);
      });
      return;
    }
    const decoded = jwtDecode<{ given_name: string; picture: string }>(token);
    setUser({
      name: decoded.given_name,
      picture: decoded.picture,
    });
    AsyncStorage.setItem("token", token).catch((err) => {
      logger.error("could not set token", err);
    });
  }, [token]);
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  const reactPaperTheme =
    colorScheme === "dark"
      ? {
          ...DarkTheme,
          colors: {
            ...DarkTheme.colors,
            background: "grey",
          },
        }
      : {
          ...DefaultTheme,
          colors: {
            ...DefaultTheme.colors,
            background: "lightgrey",
          },
        };

  const client = useMemo(() => {
    const httpLink = new HttpLink({
      uri: "https://graphql.tassin.work/graphql",
      headers: {
        authorization: "Bearer " + token,
      },
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      const errors: typeof clientErrors = [];
      errors.push(...graphQLErrors);
      graphQLErrors.forEach((err) => {
        logger.error(err.message, err);
      });
      if (networkError) {
        errors.push(networkError);
        logger.error(networkError);
      }
      setClientErrors(errors);
    });

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: from([errorLink, httpLink]),
    });
  }, [token]);

  console.log("clientErrors", clientErrors);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <PaperProvider theme={reactPaperTheme}>
        <SafeAreaProvider style={styles.container}>
          <UserContext.Provider value={{ user, token, setToken }}>
            <ApolloProvider client={client}>
              <Navigation colorScheme={colorScheme} />
              {clientErrors.length ? (
                <Snackbar
                  visible={Boolean(clientErrors.length)}
                  onDismiss={() => {}}
                  action={{
                    label: t("Dismiss"),
                    onPress: () => {
                      setClientErrors(clientErrors.slice(1));
                    },
                  }}
                >
                  <Badge onPressOut={() => {}} onPressIn={() => {}}>
                    {clientErrors.length}
                  </Badge>
                  {clientErrors[0].message}
                </Snackbar>
              ) : null}
              <StatusBar />
            </ApolloProvider>
          </UserContext.Provider>
        </SafeAreaProvider>
      </PaperProvider>
    );
  }
}
