import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  _Any: any;
  _FieldSet: any;
};

export type Account = {
  amount: Scalars['Int'];
  bankAccount?: Maybe<BankAccount>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: AccountType;
};

export type AccountInput = {
  bankAccountId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type: AccountType;
};

export const AccountType = {
  Disponibilite: 'DISPONIBILITE',
  Emprunt: 'EMPRUNT',
  EpargneBloquee: 'EPARGNE_BLOQUEE',
  EpargneNonBloque: 'EPARGNE_NON_BLOQUE',
  Immobilisation: 'IMMOBILISATION',
  Provision: 'PROVISION'
} as const;

export type AccountType = typeof AccountType[keyof typeof AccountType];
export type AllNextAutoTransactionsResult = {
  totalCount: Scalars['Int'];
};

export type AutomaticOperation = {
  account: Account;
  comment: Scalars['String'];
  context: Context;
  credit: Scalars['Int'];
  debit: Scalars['Int'];
  group: Group;
  id: Scalars['ID'];
  monthDelta: Scalars['Int'];
  nextDate: Scalars['Date'];
  post: Post;
  user?: Maybe<User>;
};

export type Balance = {
  id: Scalars['ID'];
  sum: Scalars['Int'];
  user: User;
};

export type Bank = {
  accounts: Array<BankAccount>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BankAccount = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Bilan = {
  active: Array<BilanItems>;
  passive: Array<BilanItems>;
  total: Scalars['Int'];
  totalActive: Scalars['Int'];
  totalPassive: Scalars['Int'];
};

export type BilanItem = {
  account?: Maybe<Account>;
  amount: Scalars['Int'];
  comment: Scalars['String'];
  group: Group;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type BilanItems = {
  id: Scalars['ID'];
  items: Array<BilanItem>;
  name: Scalars['String'];
};

export type BolingerBand = {
  lower?: Maybe<Scalars['Float']>;
  upper?: Maybe<Scalars['Float']>;
};

export const BuyOrSelltrend = {
  Asc: 'ASC',
  Desc: 'DESC'
} as const;

export type BuyOrSelltrend = typeof BuyOrSelltrend[keyof typeof BuyOrSelltrend];
export type ChartData = {
  contexts: Array<ChartDataContext>;
};

export type ChartDataContext = {
  amount: Scalars['Int'];
  comment: Scalars['String'];
  context: Context;
  post: Post;
  year: Scalars['Int'];
};

export type ChartDataInput = {
  contexts: Array<StatContextInput>;
};

export type ComputeAccountsAmount = {
  accounts: Array<Account>;
};

export type ComputeNextAutoTransactionsOutput = {
  groups: Array<Group>;
  transactions: Array<Transaction>;
};

export type Context = {
  id: Scalars['ID'];
  name: Scalars['String'];
  posts: Array<Post>;
};

export type CreateAutomaticOperationInput = {
  accountId: Scalars['String'];
  comment: Scalars['String'];
  contextId: Scalars['String'];
  credit: Scalars['Int'];
  debit: Scalars['Int'];
  monthDelta: Scalars['Int'];
  nextDate: Scalars['Date'];
  postId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateTransactionInput = {
  accountId: Scalars['String'];
  checked: Scalars['Boolean'];
  comment: Scalars['String'];
  contextId: Scalars['String'];
  credit: Scalars['Int'];
  date: Scalars['Date'];
  debit: Scalars['Int'];
  groupId: Scalars['String'];
  postId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type EdgeHistorical = {
  node: Historical;
};

export type EditAutomaticOperationInput = {
  accountId: Scalars['String'];
  comment: Scalars['String'];
  contextId: Scalars['String'];
  credit: Scalars['Int'];
  debit: Scalars['Int'];
  id: Scalars['String'];
  monthDelta: Scalars['Int'];
  nextDate: Scalars['Date'];
  postId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type EditTransactionInput = {
  accountId: Scalars['String'];
  checked: Scalars['Boolean'];
  comment: Scalars['String'];
  contextId: Scalars['String'];
  credit: Scalars['Int'];
  date: Scalars['Date'];
  debit: Scalars['Int'];
  groupId: Scalars['String'];
  id: Scalars['String'];
  postId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type FilterTransactionInput = {
  accountIds?: InputMaybe<Array<Scalars['String']>>;
  checked?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  contextIds?: InputMaybe<Array<Scalars['String']>>;
  groupIds?: InputMaybe<Array<Scalars['String']>>;
  postIds?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type Group = {
  accounts: Array<Account>;
  automaticOperations: Array<AutomaticOperation>;
  balances: Array<Balance>;
  contexts: Array<Context>;
  id: Scalars['ID'];
  name: Scalars['String'];
  users: Array<Maybe<User>>;
};

export type Historical = {
  adjClose?: Maybe<Scalars['Float']>;
  adjHigh?: Maybe<Scalars['Float']>;
  adjLow?: Maybe<Scalars['Float']>;
  adjOpen?: Maybe<Scalars['Float']>;
  bb20?: Maybe<BolingerBand>;
  close?: Maybe<Scalars['Float']>;
  date: Scalars['Date'];
  high?: Maybe<Scalars['Float']>;
  low?: Maybe<Scalars['Float']>;
  macd?: Maybe<Macd>;
  open?: Maybe<Scalars['Float']>;
  prediction?: Maybe<Scalars['Float']>;
  rsi14?: Maybe<Scalars['Float']>;
  sma5?: Maybe<Scalars['Float']>;
  sma10?: Maybe<Scalars['Float']>;
  sma20?: Maybe<Scalars['Float']>;
  sma50?: Maybe<Scalars['Float']>;
  sma100?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

export type InputRsiStockSignal = {
  buySignal: Scalars['Int'];
  /** how many lines of this value do we want to aford */
  maxBuys: Scalars['Int'];
  sellSignal: Scalars['Int'];
  /** The stop value in percent. 10 means that we need a 10% stop loss */
  stopLoss: Scalars['Int'];
};

export type InputSmaStockSignal = {
  buyTrend: BuyOrSelltrend;
  daysInBuyTrend: Scalars['Int'];
  daysInSellTrend: Scalars['Int'];
  /** how many lines of this value do we want to aford */
  maxBuys: Scalars['Int'];
  sellTrend: BuyOrSelltrend;
  /** The stop value in percent. 10 means that we need a 10% stop loss */
  stopLoss: Scalars['Int'];
  usedSma: Sma;
};

export type Macd = {
  histogram?: Maybe<Scalars['Float']>;
  macd?: Maybe<Scalars['Float']>;
  signal?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  addAccountToGroup: Group;
  addAccountToUser: User;
  addContextToGroup: Group;
  addPostToContext: Group;
  /** Add stats to the current user */
  addStatToUser: User;
  computeAccountsAmount: ComputeAccountsAmount;
  /** Recompute the next auto transactions for all the users */
  computeAllNextAutoTransactions: AllNextAutoTransactionsResult;
  computeNextAutoTransactions: ComputeNextAutoTransactionsOutput;
  /** Connect a new bank to the user */
  connectBank: User;
  createAutomaticOperation: Group;
  createGroup: Group;
  createTransaction: Transaction;
  deleteAutomaticOperation: Group;
  deleteTransaction: Transaction;
  editAutomaticOperation: Group;
  editContext: Group;
  editGroupAccount: Group;
  editStat: User;
  editTransaction: Transaction;
  /**
   * Edit the user in a patch mode.
   * @deprecated No longer supported
   */
  editUser: User;
  editUserAccount: User;
  followStock: User;
  /** Link an account to a budget inside bank account */
  linkBankAccountToAccount: Account;
  populatePredictions: Array<Stock>;
  refetchRandomStocks: Array<Stock>;
  refetchStockData: Stock;
  refetchStockDatas: Array<Stock>;
  restoreTransaction: Transaction;
  /** Sync the last transactions for the user from its banks */
  syncLastTransactions: Array<Transaction>;
  syncLastTransactionsForAll: Array<Scalars['String']>;
  testSignals: Array<User>;
  trainStockModels: Array<Stock>;
  unfollowStock: User;
  updateRsiSignal: User;
  /** Updates the slack channel for signals */
  updateSlackChannelIdForSignal: User;
  /** Updates the slack token for the user */
  updateSlackToken: User;
  /** Updates the sma signals for this user */
  updateSmaSignal: User;
};


export type MutationAddAccountToGroupArgs = {
  enabled: Scalars['Boolean'];
  groupId: Scalars['String'];
  name: Scalars['String'];
  type: AccountType;
};


export type MutationAddAccountToUserArgs = {
  bankAccountId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  type: AccountType;
  userId: Scalars['String'];
};


export type MutationAddContextToGroupArgs = {
  contextId: Scalars['String'];
  groupId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationAddPostToContextArgs = {
  contextId: Scalars['String'];
  groupId: Scalars['ID'];
  name: Scalars['String'];
  postId: Scalars['ID'];
};


export type MutationAddStatToUserArgs = {
  stat: StatInput;
};


export type MutationConnectBankArgs = {
  budgetInsideClientCode: Scalars['String'];
  budgetInsideClientID: Scalars['String'];
};


export type MutationCreateAutomaticOperationArgs = {
  groupId: Scalars['String'];
  input: CreateAutomaticOperationInput;
};


export type MutationCreateGroupArgs = {
  name: Scalars['String'];
};


export type MutationCreateTransactionArgs = {
  input: CreateTransactionInput;
};


export type MutationDeleteAutomaticOperationArgs = {
  groupId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteTransactionArgs = {
  id: Scalars['String'];
};


export type MutationEditAutomaticOperationArgs = {
  groupId: Scalars['String'];
  input: EditAutomaticOperationInput;
};


export type MutationEditContextArgs = {
  contextId: Scalars['String'];
  enabled: Scalars['Boolean'];
  groupId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationEditGroupAccountArgs = {
  account: AccountInput;
  groupId: Scalars['String'];
};


export type MutationEditStatArgs = {
  id: Scalars['ID'];
  stat: StatInput;
};


export type MutationEditTransactionArgs = {
  input: EditTransactionInput;
};


export type MutationEditUserArgs = {
  id: Scalars['String'];
  input: UserInput;
};


export type MutationEditUserAccountArgs = {
  account: AccountInput;
  userId: Scalars['String'];
};


export type MutationFollowStockArgs = {
  stockId: Scalars['ID'];
};


export type MutationLinkBankAccountToAccountArgs = {
  accountId: Scalars['ID'];
  budgetInsideBankAccount: Scalars['String'];
};


export type MutationPopulatePredictionsArgs = {
  id: Scalars['String'];
};


export type MutationRefetchStockDataArgs = {
  id: Scalars['String'];
};


export type MutationRestoreTransactionArgs = {
  id: Scalars['String'];
};


export type MutationUnfollowStockArgs = {
  stockId: Scalars['ID'];
};


export type MutationUpdateRsiSignalArgs = {
  input?: InputMaybe<InputRsiStockSignal>;
  stockId: Scalars['ID'];
};


export type MutationUpdateSlackChannelIdForSignalArgs = {
  channelId: Scalars['String'];
};


export type MutationUpdateSlackTokenArgs = {
  slackToken: Scalars['String'];
};


export type MutationUpdateSmaSignalArgs = {
  input?: InputMaybe<InputSmaStockSignal>;
  stockId: Scalars['ID'];
};

export type Paginable = {
  totalCount: Scalars['Int'];
};

export type PaginableHistorical = {
  edges: Array<EdgeHistorical>;
  totalCount: Scalars['Int'];
};

export type Post = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Query = {
  _service: _Service;
  /** Return all the bank connections for the user */
  banksOfUser: Array<Bank>;
  bilan: Bilan;
  chartData: ChartData;
  currentUser: User;
  groups: Array<Maybe<Group>>;
  listTransactions: TransactionsList;
  stock: Stock;
  /** List all stocks in the system. No need to be authenticated */
  stocks: Array<Stock>;
  testSlackNotif: TestSlackNotifResult;
  transactionCommentTypeAhead?: Maybe<TypeAheadResult>;
  user: User;
  users: Array<Maybe<User>>;
};


export type QueryBilanArgs = {
  date: Scalars['Date'];
};


export type QueryChartDataArgs = {
  input?: InputMaybe<ChartDataInput>;
};


export type QueryListTransactionsArgs = {
  filter?: InputMaybe<FilterTransactionInput>;
};


export type QueryStockArgs = {
  id: Scalars['ID'];
};


export type QueryTransactionCommentTypeAheadArgs = {
  comment: Scalars['String'];
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export const Role = {
  User: 'USER'
} as const;

export type Role = typeof Role[keyof typeof Role];
export type RsiStockSignal = {
  buySignal: Scalars['Int'];
  id: Scalars['ID'];
  maxBuys: Scalars['Int'];
  sellSignal: Scalars['Int'];
  /** The stop value in percent. 10 means that we need a 10% stop loss */
  stopLoss: Scalars['Int'];
};

export const Sma = {
  Sma_20: 'SMA_20',
  Sma_50: 'SMA_50',
  Sma_100: 'SMA_100'
} as const;

export type Sma = typeof Sma[keyof typeof Sma];
export type SmaStockSignal = {
  buyTrend: BuyOrSelltrend;
  daysInBuyTrend: Scalars['Int'];
  daysInSellTrend: Scalars['Int'];
  id: Scalars['ID'];
  /** how many lines of this value do we want to aford */
  maxBuys: Scalars['Int'];
  sellTrend: BuyOrSelltrend;
  /** The stop value in percent. 10 means that we need a 10% stop loss */
  stopLoss: Scalars['Int'];
  usedSma: Sma;
};

export type Stat = {
  contexts: Array<StatContext>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type StatContext = {
  context: Context;
  id: Scalars['ID'];
  post: Post;
};

export type StatContextEditInput = {
  contextId: Scalars['String'];
  id: Scalars['ID'];
  postId: Scalars['String'];
};

export type StatContextInput = {
  contextId: Scalars['String'];
  postId: Scalars['String'];
};

export type StatInput = {
  contexts: Array<StatContextEditInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Stock = {
  currentUser: UserStock;
  historical: Array<Historical>;
  historical2?: Maybe<PaginableHistorical>;
  id: Scalars['ID'];
  /** @deprecated Use stock.historical2 instead */
  name: Scalars['String'];
  symbol: Scalars['String'];
};


export type StockHistorical2Args = {
  first?: InputMaybe<Scalars['Int']>;
};

export type StockSignals = {
  id: Scalars['ID'];
  /** the rsi14 buy and sell signals */
  rsi?: Maybe<RsiStockSignal>;
  sma?: Maybe<SmaStockSignal>;
};

export type Transaction = {
  account: Account;
  checked: Scalars['Boolean'];
  comment: Scalars['String'];
  context: Context;
  credit: Scalars['Int'];
  date: Scalars['Date'];
  debit: Scalars['Int'];
  deleted: Scalars['Boolean'];
  group: Group;
  id: Scalars['ID'];
  post: Post;
  user?: Maybe<User>;
};

export type TransactionsList = {
  items: Array<Transaction>;
};

export type TypeAheadResult = {
  items: Array<Scalars['String']>;
};

export type User = {
  accounts: Array<Account>;
  firstname: Scalars['String'];
  groups: Array<Group>;
  id: Scalars['ID'];
  lastname: Scalars['String'];
  /** the slack channel for the signal */
  signalChannelId?: Maybe<Scalars['String']>;
  /** The slack token for signals */
  slackToken?: Maybe<Scalars['String']>;
  stats: Array<Stat>;
  /** The stock config for this user */
  stocks: Array<UserStock>;
};


export type UserStocksArgs = {
  follow?: InputMaybe<Scalars['Boolean']>;
};

export type UserInput = {
  accounts?: InputMaybe<Array<AccountInput>>;
  stats?: InputMaybe<Array<StatInput>>;
};

export type UserStock = {
  follow: Scalars['Boolean'];
  id: Scalars['ID'];
  signals?: Maybe<StockSignals>;
  stock: Stock;
};

export type _Service = {
  sdl?: Maybe<Scalars['String']>;
};

export type TestSlackNotifResult = {
  ok: Scalars['Boolean'];
};

export type BalancesQueryVariables = Exact<{ [key: string]: never; }>;


export type BalancesQuery = { __typename?: 'Query', groups: Array<{ __typename?: 'Group', id: string, name: string, balances: Array<{ __typename?: 'Balance', id: string, sum: number, user: { __typename?: 'User', id: string, firstname: string } }> } | null | undefined> };

export type BilanQueryVariables = Exact<{
  date: Scalars['Date'];
}>;


export type BilanQuery = { __typename?: 'Query', bilan: { __typename?: 'Bilan', totalActive: number, totalPassive: number, total: number, active: Array<{ __typename?: 'BilanItems', name: string, items: Array<{ __typename?: 'BilanItem', id: string, amount: number, comment: string, name?: string | null | undefined, account?: { __typename?: 'Account', id: string, name: string } | null | undefined }> }>, passive: Array<{ __typename?: 'BilanItems', name: string, items: Array<{ __typename?: 'BilanItem', id: string, amount: number, comment: string, name?: string | null | undefined, account?: { __typename?: 'Account', id: string, name: string } | null | undefined }> }> } };

export type BilanPreviewQueryVariables = Exact<{
  date: Scalars['Date'];
}>;


export type BilanPreviewQuery = { __typename?: 'Query', bilan: { __typename?: 'Bilan', total: number } };

export type AddContextAndPostToGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
  contextName: Scalars['String'];
  postName: Scalars['String'];
  contextId: Scalars['String'];
  postId: Scalars['ID'];
}>;


export type AddContextAndPostToGroupMutation = { __typename?: 'Mutation', addContextToGroup: { __typename?: 'Group', id: string, contexts: Array<{ __typename?: 'Context', id: string, name: string }> }, addPostToContext: { __typename?: 'Group', id: string, contexts: Array<{ __typename?: 'Context', id: string, name: string, posts: Array<{ __typename?: 'Post', id: string, name: string }> }> } };

export type AddPostToContextMutationVariables = Exact<{
  groupId: Scalars['ID'];
  name: Scalars['String'];
  contextId: Scalars['String'];
  postId: Scalars['ID'];
}>;


export type AddPostToContextMutation = { __typename?: 'Mutation', addPostToContext: { __typename?: 'Group', id: string, contexts: Array<{ __typename?: 'Context', id: string, name: string, posts: Array<{ __typename?: 'Post', id: string, name: string }> }> } };

export type CreateGroupMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateGroupMutation = { __typename?: 'Mutation', createGroup: { __typename?: 'Group', id: string, name: string, users: Array<{ __typename?: 'User', id: string } | null | undefined> } };

export type GetGroupsDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupsDetailsQuery = { __typename?: 'Query', groups: Array<{ __typename?: 'Group', id: string, name: string, contexts: Array<{ __typename?: 'Context', id: string, name: string, posts: Array<{ __typename?: 'Post', id: string, name: string }> }> } | null | undefined> };

export type ConnectBankMutationVariables = Exact<{
  budgetInsideClientID: Scalars['String'];
  budgetInsideClientCode: Scalars['String'];
}>;


export type ConnectBankMutation = { __typename?: 'Mutation', connectBank: { __typename?: 'User', id: string } };

export type BanksOfUserQueryVariables = Exact<{ [key: string]: never; }>;


export type BanksOfUserQuery = { __typename?: 'Query', banksOfUser: Array<{ __typename?: 'Bank', id: string, name: string, accounts: Array<{ __typename?: 'BankAccount', name: string, id: string }> }> };

export type AddAccountToUserMutationVariables = Exact<{
  userId: Scalars['String'];
  name: Scalars['String'];
  type: AccountType;
  enabled: Scalars['Boolean'];
  bankAccountId?: InputMaybe<Scalars['String']>;
}>;


export type AddAccountToUserMutation = { __typename?: 'Mutation', addAccountToUser: { __typename?: 'User', id: string, accounts: Array<{ __typename?: 'Account', id: string, name: string, type: AccountType, enabled?: boolean | null | undefined }> } };

export type UpdateUserAccountMutationVariables = Exact<{
  userId: Scalars['String'];
  account: AccountInput;
}>;


export type UpdateUserAccountMutation = { __typename?: 'Mutation', editUserAccount: { __typename?: 'User', id: string, accounts: Array<{ __typename?: 'Account', id: string, name: string, type: AccountType, enabled?: boolean | null | undefined }> } };

export type ComputeAccountsAmountsMutationVariables = Exact<{ [key: string]: never; }>;


export type ComputeAccountsAmountsMutation = { __typename?: 'Mutation', computeAccountsAmount: { __typename?: 'ComputeAccountsAmount', accounts: Array<{ __typename?: 'Account', id: string, amount: number }> } };

export type AccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, firstname: string, accounts: Array<{ __typename?: 'Account', id: string, amount: number, name: string, type: AccountType, enabled?: boolean | null | undefined, bankAccount?: { __typename?: 'BankAccount', id: string } | null | undefined }> }, banksOfUser: Array<{ __typename?: 'Bank', id: string, name: string, accounts: Array<{ __typename?: 'BankAccount', name: string, id: string }> }>, groups: Array<{ __typename?: 'Group', id: string, name: string, accounts: Array<{ __typename?: 'Account', id: string, amount: number, name: string, type: AccountType, enabled?: boolean | null | undefined }> } | null | undefined> };

export type AddStatToUserMutationVariables = Exact<{
  stat: StatInput;
}>;


export type AddStatToUserMutation = { __typename?: 'Mutation', addStatToUser: { __typename?: 'User', id: string, stats: Array<{ __typename?: 'Stat', id: string, name: string, contexts: Array<{ __typename?: 'StatContext', id: string, post: { __typename?: 'Post', id: string, name: string }, context: { __typename?: 'Context', id: string, name: string } }> }> } };

export type ComputeStatsQueryVariables = Exact<{
  input?: InputMaybe<ChartDataInput>;
}>;


export type ComputeStatsQuery = { __typename?: 'Query', chartData: { __typename?: 'ChartData', contexts: Array<{ __typename?: 'ChartDataContext', year: number, amount: number, comment: string, context: { __typename?: 'Context', id: string, name: string }, post: { __typename?: 'Post', id: string, name: string } }> } };

export type EditStatMutationVariables = Exact<{
  id: Scalars['ID'];
  stat: StatInput;
}>;


export type EditStatMutation = { __typename?: 'Mutation', editStat: { __typename?: 'User', id: string, stats: Array<{ __typename?: 'Stat', id: string, name: string, contexts: Array<{ __typename?: 'StatContext', id: string, post: { __typename?: 'Post', id: string, name: string }, context: { __typename?: 'Context', id: string, name: string } }> }> } };

export type EditUserStatsMutationVariables = Exact<{
  id: Scalars['String'];
  input: UserInput;
}>;


export type EditUserStatsMutation = { __typename?: 'Mutation', editUser: { __typename?: 'User', id: string, stats: Array<{ __typename?: 'Stat', id: string, name: string, contexts: Array<{ __typename?: 'StatContext', id: string, post: { __typename?: 'Post', id: string, name: string }, context: { __typename?: 'Context', id: string, name: string } }> }> } };

export type UserStatsFragment = { __typename?: 'User', id: string, stats: Array<{ __typename?: 'Stat', id: string, name: string, contexts: Array<{ __typename?: 'StatContext', id: string, post: { __typename?: 'Post', id: string, name: string }, context: { __typename?: 'Context', id: string, name: string } }> }> };

export type ChartConfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type ChartConfigsQuery = { __typename?: 'Query', groups: Array<{ __typename?: 'Group', id: string, contexts: Array<{ __typename?: 'Context', id: string, name: string, posts: Array<{ __typename?: 'Post', id: string, name: string }> }> } | null | undefined>, currentUser: { __typename?: 'User', id: string, stats: Array<{ __typename?: 'Stat', id: string, name: string, contexts: Array<{ __typename?: 'StatContext', id: string, post: { __typename?: 'Post', id: string, name: string }, context: { __typename?: 'Context', id: string, name: string } }> }> } };

export type StatsValuesForSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type StatsValuesForSelectQuery = { __typename?: 'Query', groups: Array<{ __typename?: 'Group', id: string, accounts: Array<{ __typename?: 'Account', id: string, name: string, enabled?: boolean | null | undefined }>, contexts: Array<{ __typename?: 'Context', id: string, name: string, posts: Array<{ __typename?: 'Post', id: string, name: string }> }> } | null | undefined> };

export type TransactionValuesForSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type TransactionValuesForSelectQuery = { __typename?: 'Query', groups: Array<{ __typename?: 'Group', id: string, name: string, accounts: Array<{ __typename?: 'Account', id: string, name: string, enabled?: boolean | null | undefined }>, users: Array<{ __typename?: 'User', id: string, firstname: string, accounts: Array<{ __typename?: 'Account', id: string, name: string, enabled?: boolean | null | undefined }> } | null | undefined>, contexts: Array<{ __typename?: 'Context', id: string, name: string, posts: Array<{ __typename?: 'Post', id: string, name: string }> }> } | null | undefined> };

export type DeleteTransactionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteTransactionMutation = { __typename?: 'Mutation', deleteTransaction: { __typename?: 'Transaction', id: string, date: any, comment: string, credit: number, debit: number, checked: boolean, deleted: boolean, group: { __typename?: 'Group', id: string, name: string }, user?: { __typename?: 'User', id: string, firstname: string } | null | undefined, account: { __typename?: 'Account', id: string, name: string }, post: { __typename?: 'Post', id: string, name: string }, context: { __typename?: 'Context', id: string, name: string } } };

export type RestoreTransactionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RestoreTransactionMutation = { __typename?: 'Mutation', restoreTransaction: { __typename?: 'Transaction', id: string, date: any, comment: string, credit: number, debit: number, checked: boolean, deleted: boolean, group: { __typename?: 'Group', id: string, name: string }, user?: { __typename?: 'User', id: string, firstname: string } | null | undefined, account: { __typename?: 'Account', id: string, name: string }, post: { __typename?: 'Post', id: string, name: string }, context: { __typename?: 'Context', id: string, name: string } } };

export type CreateTransactionMutationVariables = Exact<{
  date: Scalars['Date'];
  credit: Scalars['Int'];
  debit: Scalars['Int'];
  groupId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  contextId: Scalars['String'];
  accountId: Scalars['String'];
  comment: Scalars['String'];
  checked: Scalars['Boolean'];
}>;


export type CreateTransactionMutation = { __typename?: 'Mutation', createTransaction: { __typename?: 'Transaction', id: string, date: any, comment: string, credit: number, debit: number, checked: boolean, deleted: boolean, group: { __typename?: 'Group', id: string, name: string }, user?: { __typename?: 'User', id: string, firstname: string } | null | undefined, account: { __typename?: 'Account', id: string, name: string }, post: { __typename?: 'Post', id: string, name: string }, context: { __typename?: 'Context', id: string, name: string } } };

export type EditTransactionMutationVariables = Exact<{
  id: Scalars['String'];
  date: Scalars['Date'];
  credit: Scalars['Int'];
  debit: Scalars['Int'];
  groupId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  contextId: Scalars['String'];
  accountId: Scalars['String'];
  comment: Scalars['String'];
  checked: Scalars['Boolean'];
}>;


export type EditTransactionMutation = { __typename?: 'Mutation', editTransaction: { __typename?: 'Transaction', id: string, date: any, comment: string, credit: number, debit: number, checked: boolean, deleted: boolean, group: { __typename?: 'Group', id: string, name: string }, user?: { __typename?: 'User', id: string, firstname: string } | null | undefined, account: { __typename?: 'Account', id: string, name: string }, post: { __typename?: 'Post', id: string, name: string }, context: { __typename?: 'Context', id: string, name: string } } };

export type TransactionFieldsFragment = { __typename?: 'Transaction', id: string, date: any, comment: string, credit: number, debit: number, checked: boolean, deleted: boolean, group: { __typename?: 'Group', id: string, name: string }, user?: { __typename?: 'User', id: string, firstname: string } | null | undefined, account: { __typename?: 'Account', id: string, name: string }, post: { __typename?: 'Post', id: string, name: string }, context: { __typename?: 'Context', id: string, name: string } };

export type TransactionsQueryVariables = Exact<{
  comment?: InputMaybe<Scalars['String']>;
  accountIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  groupIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type TransactionsQuery = { __typename?: 'Query', listTransactions: { __typename?: 'TransactionsList', items: Array<{ __typename?: 'Transaction', id: string, date: any, comment: string, credit: number, debit: number, checked: boolean, deleted: boolean, group: { __typename?: 'Group', id: string, name: string }, user?: { __typename?: 'User', id: string, firstname: string } | null | undefined, account: { __typename?: 'Account', id: string, name: string }, post: { __typename?: 'Post', id: string, name: string }, context: { __typename?: 'Context', id: string, name: string } }> }, uncheckedTransactions: { __typename?: 'TransactionsList', items: Array<{ __typename?: 'Transaction', id: string, date: any, comment: string, credit: number, debit: number, checked: boolean, deleted: boolean, group: { __typename?: 'Group', id: string, name: string }, user?: { __typename?: 'User', id: string, firstname: string } | null | undefined, account: { __typename?: 'Account', id: string, name: string }, post: { __typename?: 'Post', id: string, name: string }, context: { __typename?: 'Context', id: string, name: string } }> } };

export type LisValuesForSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type LisValuesForSelectQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, firstname: string, accounts: Array<{ __typename?: 'Account', id: string, name: string, enabled?: boolean | null | undefined }> } | null | undefined>, groups: Array<{ __typename?: 'Group', id: string, name: string, accounts: Array<{ __typename?: 'Account', id: string, name: string, enabled?: boolean | null | undefined }>, contexts: Array<{ __typename?: 'Context', id: string, name: string, posts: Array<{ __typename?: 'Post', id: string, name: string }> }> } | null | undefined> };

export type CurrentUserAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserAccountsQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, accounts: Array<{ __typename?: 'Account', id: string, name: string, enabled?: boolean | null | undefined, amount: number }> } };

export const UserStatsFragmentDoc = gql`
    fragment userStats on User {
  id
  stats {
    id
    name
    contexts {
      id
      post {
        id
        name
      }
      context {
        id
        name
      }
    }
  }
}
    `;
export const TransactionFieldsFragmentDoc = gql`
    fragment transactionFields on Transaction {
  id
  date
  comment
  credit
  debit
  checked
  deleted
  group {
    id
    name
  }
  user {
    id
    firstname
  }
  account {
    id
    name
  }
  post {
    id
    name
  }
  context {
    id
    name
  }
}
    `;
export const BalancesDocument = gql`
    query balances {
  groups {
    id
    name
    balances {
      id
      user {
        id
        firstname
      }
      sum
    }
  }
}
    `;

/**
 * __useBalancesQuery__
 *
 * To run a query within a React component, call `useBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBalancesQuery(baseOptions?: Apollo.QueryHookOptions<BalancesQuery, BalancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BalancesQuery, BalancesQueryVariables>(BalancesDocument, options);
      }
export function useBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BalancesQuery, BalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BalancesQuery, BalancesQueryVariables>(BalancesDocument, options);
        }
export type BalancesQueryHookResult = ReturnType<typeof useBalancesQuery>;
export type BalancesLazyQueryHookResult = ReturnType<typeof useBalancesLazyQuery>;
export type BalancesQueryResult = Apollo.QueryResult<BalancesQuery, BalancesQueryVariables>;
export const BilanDocument = gql`
    query bilan($date: Date!) {
  bilan(date: $date) {
    totalActive
    totalPassive
    total
    active {
      name
      items {
        id
        amount
        comment
        name
        account {
          id
          name
        }
      }
    }
    passive {
      name
      items {
        id
        amount
        comment
        name
        account {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useBilanQuery__
 *
 * To run a query within a React component, call `useBilanQuery` and pass it any options that fit your needs.
 * When your component renders, `useBilanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBilanQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useBilanQuery(baseOptions: Apollo.QueryHookOptions<BilanQuery, BilanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BilanQuery, BilanQueryVariables>(BilanDocument, options);
      }
export function useBilanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BilanQuery, BilanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BilanQuery, BilanQueryVariables>(BilanDocument, options);
        }
export type BilanQueryHookResult = ReturnType<typeof useBilanQuery>;
export type BilanLazyQueryHookResult = ReturnType<typeof useBilanLazyQuery>;
export type BilanQueryResult = Apollo.QueryResult<BilanQuery, BilanQueryVariables>;
export const BilanPreviewDocument = gql`
    query bilanPreview($date: Date!) {
  bilan(date: $date) {
    total
  }
}
    `;

/**
 * __useBilanPreviewQuery__
 *
 * To run a query within a React component, call `useBilanPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBilanPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBilanPreviewQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useBilanPreviewQuery(baseOptions: Apollo.QueryHookOptions<BilanPreviewQuery, BilanPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BilanPreviewQuery, BilanPreviewQueryVariables>(BilanPreviewDocument, options);
      }
export function useBilanPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BilanPreviewQuery, BilanPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BilanPreviewQuery, BilanPreviewQueryVariables>(BilanPreviewDocument, options);
        }
export type BilanPreviewQueryHookResult = ReturnType<typeof useBilanPreviewQuery>;
export type BilanPreviewLazyQueryHookResult = ReturnType<typeof useBilanPreviewLazyQuery>;
export type BilanPreviewQueryResult = Apollo.QueryResult<BilanPreviewQuery, BilanPreviewQueryVariables>;
export const AddContextAndPostToGroupDocument = gql`
    mutation addContextAndPostToGroup($groupId: ID!, $contextName: String!, $postName: String!, $contextId: String!, $postId: ID!) {
  addContextToGroup(groupId: $groupId, name: $contextName, contextId: $contextId) {
    id
    contexts {
      id
      name
    }
  }
  addPostToContext(
    groupId: $groupId
    name: $postName
    contextId: $contextId
    postId: $postId
  ) {
    id
    contexts {
      id
      name
      posts {
        id
        name
      }
    }
  }
}
    `;
export type AddContextAndPostToGroupMutationFn = Apollo.MutationFunction<AddContextAndPostToGroupMutation, AddContextAndPostToGroupMutationVariables>;

/**
 * __useAddContextAndPostToGroupMutation__
 *
 * To run a mutation, you first call `useAddContextAndPostToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContextAndPostToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContextAndPostToGroupMutation, { data, loading, error }] = useAddContextAndPostToGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      contextName: // value for 'contextName'
 *      postName: // value for 'postName'
 *      contextId: // value for 'contextId'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useAddContextAndPostToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddContextAndPostToGroupMutation, AddContextAndPostToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddContextAndPostToGroupMutation, AddContextAndPostToGroupMutationVariables>(AddContextAndPostToGroupDocument, options);
      }
export type AddContextAndPostToGroupMutationHookResult = ReturnType<typeof useAddContextAndPostToGroupMutation>;
export type AddContextAndPostToGroupMutationResult = Apollo.MutationResult<AddContextAndPostToGroupMutation>;
export type AddContextAndPostToGroupMutationOptions = Apollo.BaseMutationOptions<AddContextAndPostToGroupMutation, AddContextAndPostToGroupMutationVariables>;
export const AddPostToContextDocument = gql`
    mutation addPostToContext($groupId: ID!, $name: String!, $contextId: String!, $postId: ID!) {
  addPostToContext(
    groupId: $groupId
    name: $name
    contextId: $contextId
    postId: $postId
  ) {
    id
    contexts {
      id
      name
      posts {
        id
        name
      }
    }
  }
}
    `;
export type AddPostToContextMutationFn = Apollo.MutationFunction<AddPostToContextMutation, AddPostToContextMutationVariables>;

/**
 * __useAddPostToContextMutation__
 *
 * To run a mutation, you first call `useAddPostToContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostToContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostToContextMutation, { data, loading, error }] = useAddPostToContextMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      name: // value for 'name'
 *      contextId: // value for 'contextId'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useAddPostToContextMutation(baseOptions?: Apollo.MutationHookOptions<AddPostToContextMutation, AddPostToContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPostToContextMutation, AddPostToContextMutationVariables>(AddPostToContextDocument, options);
      }
export type AddPostToContextMutationHookResult = ReturnType<typeof useAddPostToContextMutation>;
export type AddPostToContextMutationResult = Apollo.MutationResult<AddPostToContextMutation>;
export type AddPostToContextMutationOptions = Apollo.BaseMutationOptions<AddPostToContextMutation, AddPostToContextMutationVariables>;
export const CreateGroupDocument = gql`
    mutation createGroup($name: String!) {
  createGroup(name: $name) {
    id
    name
    users {
      id
    }
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const GetGroupsDetailsDocument = gql`
    query getGroupsDetails {
  groups {
    id
    name
    contexts {
      id
      name
      posts {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetGroupsDetailsQuery__
 *
 * To run a query within a React component, call `useGetGroupsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupsDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables>(GetGroupsDetailsDocument, options);
      }
export function useGetGroupsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables>(GetGroupsDetailsDocument, options);
        }
export type GetGroupsDetailsQueryHookResult = ReturnType<typeof useGetGroupsDetailsQuery>;
export type GetGroupsDetailsLazyQueryHookResult = ReturnType<typeof useGetGroupsDetailsLazyQuery>;
export type GetGroupsDetailsQueryResult = Apollo.QueryResult<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables>;
export const ConnectBankDocument = gql`
    mutation connectBank($budgetInsideClientID: String!, $budgetInsideClientCode: String!) {
  connectBank(
    budgetInsideClientCode: $budgetInsideClientCode
    budgetInsideClientID: $budgetInsideClientID
  ) {
    id
  }
}
    `;
export type ConnectBankMutationFn = Apollo.MutationFunction<ConnectBankMutation, ConnectBankMutationVariables>;

/**
 * __useConnectBankMutation__
 *
 * To run a mutation, you first call `useConnectBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectBankMutation, { data, loading, error }] = useConnectBankMutation({
 *   variables: {
 *      budgetInsideClientID: // value for 'budgetInsideClientID'
 *      budgetInsideClientCode: // value for 'budgetInsideClientCode'
 *   },
 * });
 */
export function useConnectBankMutation(baseOptions?: Apollo.MutationHookOptions<ConnectBankMutation, ConnectBankMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectBankMutation, ConnectBankMutationVariables>(ConnectBankDocument, options);
      }
export type ConnectBankMutationHookResult = ReturnType<typeof useConnectBankMutation>;
export type ConnectBankMutationResult = Apollo.MutationResult<ConnectBankMutation>;
export type ConnectBankMutationOptions = Apollo.BaseMutationOptions<ConnectBankMutation, ConnectBankMutationVariables>;
export const BanksOfUserDocument = gql`
    query banksOfUser {
  banksOfUser {
    id
    name
    accounts {
      name
      id
    }
  }
}
    `;

/**
 * __useBanksOfUserQuery__
 *
 * To run a query within a React component, call `useBanksOfUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useBanksOfUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBanksOfUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useBanksOfUserQuery(baseOptions?: Apollo.QueryHookOptions<BanksOfUserQuery, BanksOfUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BanksOfUserQuery, BanksOfUserQueryVariables>(BanksOfUserDocument, options);
      }
export function useBanksOfUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BanksOfUserQuery, BanksOfUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BanksOfUserQuery, BanksOfUserQueryVariables>(BanksOfUserDocument, options);
        }
export type BanksOfUserQueryHookResult = ReturnType<typeof useBanksOfUserQuery>;
export type BanksOfUserLazyQueryHookResult = ReturnType<typeof useBanksOfUserLazyQuery>;
export type BanksOfUserQueryResult = Apollo.QueryResult<BanksOfUserQuery, BanksOfUserQueryVariables>;
export const AddAccountToUserDocument = gql`
    mutation addAccountToUser($userId: String!, $name: String!, $type: AccountType!, $enabled: Boolean!, $bankAccountId: String) {
  addAccountToUser(
    userId: $userId
    name: $name
    type: $type
    enabled: $enabled
    bankAccountId: $bankAccountId
  ) {
    id
    accounts {
      id
      name
      type
      enabled
    }
  }
}
    `;
export type AddAccountToUserMutationFn = Apollo.MutationFunction<AddAccountToUserMutation, AddAccountToUserMutationVariables>;

/**
 * __useAddAccountToUserMutation__
 *
 * To run a mutation, you first call `useAddAccountToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountToUserMutation, { data, loading, error }] = useAddAccountToUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      enabled: // value for 'enabled'
 *      bankAccountId: // value for 'bankAccountId'
 *   },
 * });
 */
export function useAddAccountToUserMutation(baseOptions?: Apollo.MutationHookOptions<AddAccountToUserMutation, AddAccountToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAccountToUserMutation, AddAccountToUserMutationVariables>(AddAccountToUserDocument, options);
      }
export type AddAccountToUserMutationHookResult = ReturnType<typeof useAddAccountToUserMutation>;
export type AddAccountToUserMutationResult = Apollo.MutationResult<AddAccountToUserMutation>;
export type AddAccountToUserMutationOptions = Apollo.BaseMutationOptions<AddAccountToUserMutation, AddAccountToUserMutationVariables>;
export const UpdateUserAccountDocument = gql`
    mutation updateUserAccount($userId: String!, $account: AccountInput!) {
  editUserAccount(userId: $userId, account: $account) {
    id
    accounts {
      id
      name
      type
      enabled
    }
  }
}
    `;
export type UpdateUserAccountMutationFn = Apollo.MutationFunction<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;

/**
 * __useUpdateUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountMutation, { data, loading, error }] = useUpdateUserAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      account: // value for 'account'
 *   },
 * });
 */
export function useUpdateUserAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>(UpdateUserAccountDocument, options);
      }
export type UpdateUserAccountMutationHookResult = ReturnType<typeof useUpdateUserAccountMutation>;
export type UpdateUserAccountMutationResult = Apollo.MutationResult<UpdateUserAccountMutation>;
export type UpdateUserAccountMutationOptions = Apollo.BaseMutationOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;
export const ComputeAccountsAmountsDocument = gql`
    mutation computeAccountsAmounts {
  computeAccountsAmount {
    accounts {
      id
      amount
    }
  }
}
    `;
export type ComputeAccountsAmountsMutationFn = Apollo.MutationFunction<ComputeAccountsAmountsMutation, ComputeAccountsAmountsMutationVariables>;

/**
 * __useComputeAccountsAmountsMutation__
 *
 * To run a mutation, you first call `useComputeAccountsAmountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useComputeAccountsAmountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [computeAccountsAmountsMutation, { data, loading, error }] = useComputeAccountsAmountsMutation({
 *   variables: {
 *   },
 * });
 */
export function useComputeAccountsAmountsMutation(baseOptions?: Apollo.MutationHookOptions<ComputeAccountsAmountsMutation, ComputeAccountsAmountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ComputeAccountsAmountsMutation, ComputeAccountsAmountsMutationVariables>(ComputeAccountsAmountsDocument, options);
      }
export type ComputeAccountsAmountsMutationHookResult = ReturnType<typeof useComputeAccountsAmountsMutation>;
export type ComputeAccountsAmountsMutationResult = Apollo.MutationResult<ComputeAccountsAmountsMutation>;
export type ComputeAccountsAmountsMutationOptions = Apollo.BaseMutationOptions<ComputeAccountsAmountsMutation, ComputeAccountsAmountsMutationVariables>;
export const AccountsDocument = gql`
    query accounts {
  currentUser {
    id
    firstname
    accounts {
      id
      amount
      name
      type
      enabled
      bankAccount {
        id
      }
    }
  }
  banksOfUser {
    id
    name
    accounts {
      name
      id
    }
  }
  groups {
    id
    name
    accounts {
      id
      amount
      name
      type
      enabled
    }
  }
}
    `;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const AddStatToUserDocument = gql`
    mutation addStatToUser($stat: StatInput!) {
  addStatToUser(stat: $stat) {
    ...userStats
  }
}
    ${UserStatsFragmentDoc}`;
export type AddStatToUserMutationFn = Apollo.MutationFunction<AddStatToUserMutation, AddStatToUserMutationVariables>;

/**
 * __useAddStatToUserMutation__
 *
 * To run a mutation, you first call `useAddStatToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStatToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStatToUserMutation, { data, loading, error }] = useAddStatToUserMutation({
 *   variables: {
 *      stat: // value for 'stat'
 *   },
 * });
 */
export function useAddStatToUserMutation(baseOptions?: Apollo.MutationHookOptions<AddStatToUserMutation, AddStatToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStatToUserMutation, AddStatToUserMutationVariables>(AddStatToUserDocument, options);
      }
export type AddStatToUserMutationHookResult = ReturnType<typeof useAddStatToUserMutation>;
export type AddStatToUserMutationResult = Apollo.MutationResult<AddStatToUserMutation>;
export type AddStatToUserMutationOptions = Apollo.BaseMutationOptions<AddStatToUserMutation, AddStatToUserMutationVariables>;
export const ComputeStatsDocument = gql`
    query computeStats($input: ChartDataInput) {
  chartData(input: $input) {
    contexts {
      year
      amount
      context {
        id
        name
      }
      post {
        id
        name
      }
      comment
    }
  }
}
    `;

/**
 * __useComputeStatsQuery__
 *
 * To run a query within a React component, call `useComputeStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useComputeStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComputeStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useComputeStatsQuery(baseOptions?: Apollo.QueryHookOptions<ComputeStatsQuery, ComputeStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComputeStatsQuery, ComputeStatsQueryVariables>(ComputeStatsDocument, options);
      }
export function useComputeStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComputeStatsQuery, ComputeStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComputeStatsQuery, ComputeStatsQueryVariables>(ComputeStatsDocument, options);
        }
export type ComputeStatsQueryHookResult = ReturnType<typeof useComputeStatsQuery>;
export type ComputeStatsLazyQueryHookResult = ReturnType<typeof useComputeStatsLazyQuery>;
export type ComputeStatsQueryResult = Apollo.QueryResult<ComputeStatsQuery, ComputeStatsQueryVariables>;
export const EditStatDocument = gql`
    mutation editStat($id: ID!, $stat: StatInput!) {
  editStat(id: $id, stat: $stat) {
    ...userStats
  }
}
    ${UserStatsFragmentDoc}`;
export type EditStatMutationFn = Apollo.MutationFunction<EditStatMutation, EditStatMutationVariables>;

/**
 * __useEditStatMutation__
 *
 * To run a mutation, you first call `useEditStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStatMutation, { data, loading, error }] = useEditStatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stat: // value for 'stat'
 *   },
 * });
 */
export function useEditStatMutation(baseOptions?: Apollo.MutationHookOptions<EditStatMutation, EditStatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditStatMutation, EditStatMutationVariables>(EditStatDocument, options);
      }
export type EditStatMutationHookResult = ReturnType<typeof useEditStatMutation>;
export type EditStatMutationResult = Apollo.MutationResult<EditStatMutation>;
export type EditStatMutationOptions = Apollo.BaseMutationOptions<EditStatMutation, EditStatMutationVariables>;
export const EditUserStatsDocument = gql`
    mutation editUserStats($id: String!, $input: UserInput!) {
  editUser(id: $id, input: $input) {
    ...userStats
  }
}
    ${UserStatsFragmentDoc}`;
export type EditUserStatsMutationFn = Apollo.MutationFunction<EditUserStatsMutation, EditUserStatsMutationVariables>;

/**
 * __useEditUserStatsMutation__
 *
 * To run a mutation, you first call `useEditUserStatsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserStatsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserStatsMutation, { data, loading, error }] = useEditUserStatsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserStatsMutation(baseOptions?: Apollo.MutationHookOptions<EditUserStatsMutation, EditUserStatsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserStatsMutation, EditUserStatsMutationVariables>(EditUserStatsDocument, options);
      }
export type EditUserStatsMutationHookResult = ReturnType<typeof useEditUserStatsMutation>;
export type EditUserStatsMutationResult = Apollo.MutationResult<EditUserStatsMutation>;
export type EditUserStatsMutationOptions = Apollo.BaseMutationOptions<EditUserStatsMutation, EditUserStatsMutationVariables>;
export const ChartConfigsDocument = gql`
    query chartConfigs {
  groups {
    id
    contexts {
      id
      name
      posts {
        id
        name
      }
    }
  }
  currentUser {
    ...userStats
  }
}
    ${UserStatsFragmentDoc}`;

/**
 * __useChartConfigsQuery__
 *
 * To run a query within a React component, call `useChartConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useChartConfigsQuery(baseOptions?: Apollo.QueryHookOptions<ChartConfigsQuery, ChartConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartConfigsQuery, ChartConfigsQueryVariables>(ChartConfigsDocument, options);
      }
export function useChartConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartConfigsQuery, ChartConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartConfigsQuery, ChartConfigsQueryVariables>(ChartConfigsDocument, options);
        }
export type ChartConfigsQueryHookResult = ReturnType<typeof useChartConfigsQuery>;
export type ChartConfigsLazyQueryHookResult = ReturnType<typeof useChartConfigsLazyQuery>;
export type ChartConfigsQueryResult = Apollo.QueryResult<ChartConfigsQuery, ChartConfigsQueryVariables>;
export const StatsValuesForSelectDocument = gql`
    query statsValuesForSelect {
  groups {
    id
    accounts {
      id
      name
      enabled
    }
    contexts {
      id
      name
      posts {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useStatsValuesForSelectQuery__
 *
 * To run a query within a React component, call `useStatsValuesForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsValuesForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsValuesForSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatsValuesForSelectQuery(baseOptions?: Apollo.QueryHookOptions<StatsValuesForSelectQuery, StatsValuesForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatsValuesForSelectQuery, StatsValuesForSelectQueryVariables>(StatsValuesForSelectDocument, options);
      }
export function useStatsValuesForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatsValuesForSelectQuery, StatsValuesForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatsValuesForSelectQuery, StatsValuesForSelectQueryVariables>(StatsValuesForSelectDocument, options);
        }
export type StatsValuesForSelectQueryHookResult = ReturnType<typeof useStatsValuesForSelectQuery>;
export type StatsValuesForSelectLazyQueryHookResult = ReturnType<typeof useStatsValuesForSelectLazyQuery>;
export type StatsValuesForSelectQueryResult = Apollo.QueryResult<StatsValuesForSelectQuery, StatsValuesForSelectQueryVariables>;
export const TransactionValuesForSelectDocument = gql`
    query transactionValuesForSelect {
  groups {
    id
    name
    accounts {
      id
      name
      enabled
    }
    users {
      id
      firstname
      accounts {
        id
        name
        enabled
      }
    }
    contexts {
      id
      name
      posts {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useTransactionValuesForSelectQuery__
 *
 * To run a query within a React component, call `useTransactionValuesForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionValuesForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionValuesForSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransactionValuesForSelectQuery(baseOptions?: Apollo.QueryHookOptions<TransactionValuesForSelectQuery, TransactionValuesForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionValuesForSelectQuery, TransactionValuesForSelectQueryVariables>(TransactionValuesForSelectDocument, options);
      }
export function useTransactionValuesForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionValuesForSelectQuery, TransactionValuesForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionValuesForSelectQuery, TransactionValuesForSelectQueryVariables>(TransactionValuesForSelectDocument, options);
        }
export type TransactionValuesForSelectQueryHookResult = ReturnType<typeof useTransactionValuesForSelectQuery>;
export type TransactionValuesForSelectLazyQueryHookResult = ReturnType<typeof useTransactionValuesForSelectLazyQuery>;
export type TransactionValuesForSelectQueryResult = Apollo.QueryResult<TransactionValuesForSelectQuery, TransactionValuesForSelectQueryVariables>;
export const DeleteTransactionDocument = gql`
    mutation deleteTransaction($id: String!) {
  deleteTransaction(id: $id) {
    ...transactionFields
  }
}
    ${TransactionFieldsFragmentDoc}`;
export type DeleteTransactionMutationFn = Apollo.MutationFunction<DeleteTransactionMutation, DeleteTransactionMutationVariables>;

/**
 * __useDeleteTransactionMutation__
 *
 * To run a mutation, you first call `useDeleteTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransactionMutation, { data, loading, error }] = useDeleteTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTransactionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTransactionMutation, DeleteTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTransactionMutation, DeleteTransactionMutationVariables>(DeleteTransactionDocument, options);
      }
export type DeleteTransactionMutationHookResult = ReturnType<typeof useDeleteTransactionMutation>;
export type DeleteTransactionMutationResult = Apollo.MutationResult<DeleteTransactionMutation>;
export type DeleteTransactionMutationOptions = Apollo.BaseMutationOptions<DeleteTransactionMutation, DeleteTransactionMutationVariables>;
export const RestoreTransactionDocument = gql`
    mutation restoreTransaction($id: String!) {
  restoreTransaction(id: $id) {
    ...transactionFields
  }
}
    ${TransactionFieldsFragmentDoc}`;
export type RestoreTransactionMutationFn = Apollo.MutationFunction<RestoreTransactionMutation, RestoreTransactionMutationVariables>;

/**
 * __useRestoreTransactionMutation__
 *
 * To run a mutation, you first call `useRestoreTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreTransactionMutation, { data, loading, error }] = useRestoreTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreTransactionMutation(baseOptions?: Apollo.MutationHookOptions<RestoreTransactionMutation, RestoreTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreTransactionMutation, RestoreTransactionMutationVariables>(RestoreTransactionDocument, options);
      }
export type RestoreTransactionMutationHookResult = ReturnType<typeof useRestoreTransactionMutation>;
export type RestoreTransactionMutationResult = Apollo.MutationResult<RestoreTransactionMutation>;
export type RestoreTransactionMutationOptions = Apollo.BaseMutationOptions<RestoreTransactionMutation, RestoreTransactionMutationVariables>;
export const CreateTransactionDocument = gql`
    mutation createTransaction($date: Date!, $credit: Int!, $debit: Int!, $groupId: String!, $userId: String, $postId: String!, $contextId: String!, $accountId: String!, $comment: String!, $checked: Boolean!) {
  createTransaction(
    input: {date: $date, credit: $credit, debit: $debit, groupId: $groupId, userId: $userId, postId: $postId, contextId: $contextId, accountId: $accountId, comment: $comment, checked: $checked}
  ) {
    ...transactionFields
  }
}
    ${TransactionFieldsFragmentDoc}`;
export type CreateTransactionMutationFn = Apollo.MutationFunction<CreateTransactionMutation, CreateTransactionMutationVariables>;

/**
 * __useCreateTransactionMutation__
 *
 * To run a mutation, you first call `useCreateTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransactionMutation, { data, loading, error }] = useCreateTransactionMutation({
 *   variables: {
 *      date: // value for 'date'
 *      credit: // value for 'credit'
 *      debit: // value for 'debit'
 *      groupId: // value for 'groupId'
 *      userId: // value for 'userId'
 *      postId: // value for 'postId'
 *      contextId: // value for 'contextId'
 *      accountId: // value for 'accountId'
 *      comment: // value for 'comment'
 *      checked: // value for 'checked'
 *   },
 * });
 */
export function useCreateTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateTransactionMutation, CreateTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTransactionMutation, CreateTransactionMutationVariables>(CreateTransactionDocument, options);
      }
export type CreateTransactionMutationHookResult = ReturnType<typeof useCreateTransactionMutation>;
export type CreateTransactionMutationResult = Apollo.MutationResult<CreateTransactionMutation>;
export type CreateTransactionMutationOptions = Apollo.BaseMutationOptions<CreateTransactionMutation, CreateTransactionMutationVariables>;
export const EditTransactionDocument = gql`
    mutation editTransaction($id: String!, $date: Date!, $credit: Int!, $debit: Int!, $groupId: String!, $userId: String, $postId: String!, $contextId: String!, $accountId: String!, $comment: String!, $checked: Boolean!) {
  editTransaction(
    input: {id: $id, date: $date, credit: $credit, debit: $debit, groupId: $groupId, userId: $userId, postId: $postId, contextId: $contextId, accountId: $accountId, comment: $comment, checked: $checked}
  ) {
    ...transactionFields
  }
}
    ${TransactionFieldsFragmentDoc}`;
export type EditTransactionMutationFn = Apollo.MutationFunction<EditTransactionMutation, EditTransactionMutationVariables>;

/**
 * __useEditTransactionMutation__
 *
 * To run a mutation, you first call `useEditTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTransactionMutation, { data, loading, error }] = useEditTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *      credit: // value for 'credit'
 *      debit: // value for 'debit'
 *      groupId: // value for 'groupId'
 *      userId: // value for 'userId'
 *      postId: // value for 'postId'
 *      contextId: // value for 'contextId'
 *      accountId: // value for 'accountId'
 *      comment: // value for 'comment'
 *      checked: // value for 'checked'
 *   },
 * });
 */
export function useEditTransactionMutation(baseOptions?: Apollo.MutationHookOptions<EditTransactionMutation, EditTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTransactionMutation, EditTransactionMutationVariables>(EditTransactionDocument, options);
      }
export type EditTransactionMutationHookResult = ReturnType<typeof useEditTransactionMutation>;
export type EditTransactionMutationResult = Apollo.MutationResult<EditTransactionMutation>;
export type EditTransactionMutationOptions = Apollo.BaseMutationOptions<EditTransactionMutation, EditTransactionMutationVariables>;
export const TransactionsDocument = gql`
    query transactions($comment: String, $accountIds: [String!], $userIds: [String!], $groupIds: [String!]) {
  listTransactions: listTransactions(
    filter: {comment: $comment, checked: true, accountIds: $accountIds, userIds: $userIds, groupIds: $groupIds}
  ) {
    items {
      ...transactionFields
    }
  }
  uncheckedTransactions: listTransactions(
    filter: {checked: false, comment: $comment, accountIds: $accountIds, userIds: $userIds, groupIds: $groupIds}
  ) {
    items {
      ...transactionFields
    }
  }
}
    ${TransactionFieldsFragmentDoc}`;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a React component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsQuery({
 *   variables: {
 *      comment: // value for 'comment'
 *      accountIds: // value for 'accountIds'
 *      userIds: // value for 'userIds'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, options);
      }
export function useTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, options);
        }
export type TransactionsQueryHookResult = ReturnType<typeof useTransactionsQuery>;
export type TransactionsLazyQueryHookResult = ReturnType<typeof useTransactionsLazyQuery>;
export type TransactionsQueryResult = Apollo.QueryResult<TransactionsQuery, TransactionsQueryVariables>;
export const LisValuesForSelectDocument = gql`
    query lisValuesForSelect {
  users {
    id
    firstname
    accounts {
      id
      name
      enabled
    }
  }
  groups {
    id
    name
    accounts {
      id
      name
      enabled
    }
    contexts {
      id
      name
      posts {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useLisValuesForSelectQuery__
 *
 * To run a query within a React component, call `useLisValuesForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLisValuesForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLisValuesForSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useLisValuesForSelectQuery(baseOptions?: Apollo.QueryHookOptions<LisValuesForSelectQuery, LisValuesForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LisValuesForSelectQuery, LisValuesForSelectQueryVariables>(LisValuesForSelectDocument, options);
      }
export function useLisValuesForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LisValuesForSelectQuery, LisValuesForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LisValuesForSelectQuery, LisValuesForSelectQueryVariables>(LisValuesForSelectDocument, options);
        }
export type LisValuesForSelectQueryHookResult = ReturnType<typeof useLisValuesForSelectQuery>;
export type LisValuesForSelectLazyQueryHookResult = ReturnType<typeof useLisValuesForSelectLazyQuery>;
export type LisValuesForSelectQueryResult = Apollo.QueryResult<LisValuesForSelectQuery, LisValuesForSelectQueryVariables>;
export const CurrentUserAccountsDocument = gql`
    query currentUserAccounts {
  currentUser {
    id
    accounts {
      id
      name
      enabled
      amount
    }
  }
}
    `;

/**
 * __useCurrentUserAccountsQuery__
 *
 * To run a query within a React component, call `useCurrentUserAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserAccountsQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserAccountsQuery, CurrentUserAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserAccountsQuery, CurrentUserAccountsQueryVariables>(CurrentUserAccountsDocument, options);
      }
export function useCurrentUserAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserAccountsQuery, CurrentUserAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserAccountsQuery, CurrentUserAccountsQueryVariables>(CurrentUserAccountsDocument, options);
        }
export type CurrentUserAccountsQueryHookResult = ReturnType<typeof useCurrentUserAccountsQuery>;
export type CurrentUserAccountsLazyQueryHookResult = ReturnType<typeof useCurrentUserAccountsLazyQuery>;
export type CurrentUserAccountsQueryResult = Apollo.QueryResult<CurrentUserAccountsQuery, CurrentUserAccountsQueryVariables>;