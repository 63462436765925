import { FontAwesome } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
  NavigationContainer,
  useNavigation,
  useNavigationState,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { t } from "i18next";
import React from "react";
import { Button, ColorSchemeName, Pressable } from "react-native";
import {
  DarkTheme as PaperDarkTheme,
  DefaultTheme as PaperDefaultTheme,
} from "react-native-paper";
import Colors from "../constants/Colors";
import { UserContext } from "../context/UserContext";
import useColorScheme from "../hooks/useColorScheme";
import AccountsListScreen from "../screens/AccountsListScreen";
import Accounts from "../screens/AccountsScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import TabTwoScreen from "../screens/TabTwoScreen";
import UserScreen from "../screens/UserScreen";
import {
  RootStackParamList,
  RootTabParamList,
  RootTabScreenProps,
} from "../types";
import LinkingConfiguration from "./LinkingConfiguration";
import TransactionsListScreen from "../screens/TransactionsListScreen";
import BilanScreen from "../screens/BilanScreen";
import StatsScreen from "../screens/StatsScreen";
import GroupsScreen from "../screens/GroupsScreen";
import AccountingConnect from "../screens/AccountingConnect";

const CombinedDefaultTheme = {
  ...PaperDefaultTheme,
  ...NavigationDefaultTheme,
  colors: {
    ...PaperDefaultTheme.colors,
    ...NavigationDefaultTheme.colors,
    card: "lightblue",
  },
};
const CombinedDarkTheme = {
  ...PaperDarkTheme,
  ...NavigationDarkTheme,
  colors: {
    ...PaperDarkTheme.colors,
    ...NavigationDarkTheme.colors,
    card: "#435273",
  },
};

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? CombinedDarkTheme : CombinedDefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

const Stack = createNativeStackNavigator<RootStackParamList>();

function HeaderLeft() {
  const colorScheme = useColorScheme();
  const navigation = useNavigation();

  return (
    <Pressable
      onPress={() => {
        navigation.navigate("Root");
      }}
    >
      <FontAwesome
        name="home"
        size={25}
        color={Colors[colorScheme].text}
        style={{ marginRight: 15, marginLeft: 15 }}
      ></FontAwesome>
    </Pressable>
  );
}

function RootNavigator() {
  return (
    <Stack.Navigator initialRouteName="Root">
      <Stack.Screen
        name="Root"
        component={BottomTabNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
          options={{
            title: t("Connecting ..."),
            headerLeft: HeaderLeft,
          }}
          name="accounting.connect"
          component={AccountingConnect}
        />
      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          options={{
            title: t("Accounts details"),
            headerLeft: HeaderLeft,
          }}
          name="accounts.list"
          component={AccountsListScreen}
        />
        <Stack.Screen
          options={{ title: t("Transactions"), headerLeft: HeaderLeft }}
          name="transactions.list"
          component={TransactionsListScreen}
        />
        <Stack.Screen
          options={{ title: t("Bilan"), headerLeft: HeaderLeft }}
          name="bilan"
          component={BilanScreen}
        />
        <Stack.Screen
          options={{ title: t("Stats"), headerLeft: HeaderLeft }}
          name="stats"
          component={StatsScreen}
        />
        <Stack.Screen
          options={{ title: t("Groups"), headerLeft: HeaderLeft }}
          name="groups.list"
          component={GroupsScreen}
        />
      </Stack.Group>
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: "Oops!" }}
      />
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <UserContext.Consumer>
      {({ user }) =>
        user ? (
          <BottomTab.Navigator
            initialRouteName="User"
            screenOptions={{
              tabBarActiveTintColor: Colors[colorScheme].tint,
            }}
          >
            <BottomTab.Screen
              name="accounts"
              component={Accounts}
              options={({ navigation }: RootTabScreenProps<"accounts">) => ({
                title: "Comptes",
                tabBarIcon: ({ color }) => (
                  <TabBarIcon name="list" color={color} />
                ),
                headerRight: () => (
                  <Pressable
                    onPress={() => navigation.navigate("accounts.list")}
                    style={({ pressed }) => ({
                      opacity: pressed ? 0.5 : 1,
                    })}
                  >
                    <FontAwesome
                      name="cogs"
                      size={25}
                      color={Colors[colorScheme].text}
                      style={{ marginRight: 15 }}
                    />
                  </Pressable>
                ),
              })}
            />
            <BottomTab.Screen
              name="stocks"
              component={TabTwoScreen}
              options={{
                title: "Actions",
                tabBarIcon: ({ color }) => (
                  <TabBarIcon name="line-chart" color={color} />
                ),
              }}
            />
            <BottomTab.Screen
              name="User"
              component={UserScreen}
              options={{
                title: "Profile",
                tabBarIcon: ({ color }) => (
                  <TabBarIcon name="user" color={color} />
                ),
              }}
            />
          </BottomTab.Navigator>
        ) : (
          <BottomTab.Navigator
            screenOptions={{
              tabBarActiveTintColor: Colors[colorScheme].tint,
            }}
          >
            <BottomTab.Screen
              name="User"
              component={UserScreen}
              options={{
                title: "Profile",
                tabBarIcon: ({ color }) => (
                  <TabBarIcon name="user" color={color} />
                ),
              }}
            />
          </BottomTab.Navigator>
        )
      }
    </UserContext.Consumer>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>["name"];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}
