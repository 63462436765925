import * as AuthSession from "expo-auth-session";
import * as WebBrowser from "expo-web-browser";
import { useTranslation } from "react-i18next";
import { Alert, Button, Platform, StyleSheet, Linking } from "react-native";
import { Text, View } from "../components/Themed";
import { UserContext } from "../context/UserContext";
import { logger } from "../services/logger";
import { RootStackScreenProps } from "../types";

WebBrowser.maybeCompleteAuthSession();

const auth0ClientId = "kZHvLggJTzyAoCcHLilWWDw3h0ra5aGL";
const authorizationEndpoint = "https://julientassin.eu.auth0.com/authorize";

const useProxy = Platform.select({ web: false, default: true });
const redirectUri = AuthSession.makeRedirectUri({ useProxy });

export default function UserScreen({
  navigation,
}: RootStackScreenProps<"Root">) {
  const { t, i18n } = useTranslation();

  const [request, result, promptAsync] = AuthSession.useAuthRequest(
    {
      redirectUri,
      clientId: auth0ClientId,
      responseType: "id_token",
      scopes: ["openid", "profile"],
      extraParams: {
        nonce: "nonce",
      },
    },
    { authorizationEndpoint }
  );

  return (
    <View style={styles.container}>
      <UserContext.Consumer>
        {({ user, setToken }) =>
          user ? (
            <View style={styles.container}>
              <Text style={styles.title}>{t("profil")}</Text>
              <View
                style={styles.separator}
                lightColor="#eee"
                darkColor="rgba(255,255,255,0.1)"
              />
              <Text style={styles.userName}>
                {t("authentified as")} {user?.name}
              </Text>
              <View
                style={styles.separator}
                lightColor="#eee"
                darkColor="rgba(255,255,255,0.1)"
              />
              <Button
                title={t("logout")}
                onPress={() => {
                  logger.debug("logout");
                  Linking.openURL(`https://julientassin.eu.auth0.com/v2/logout`).then(() => {
                    setToken(null);
                  });
                }}
              />
            </View>
          ) : (
            <>
              <Button
                disabled={!request}
                title="Login"
                onPress={() =>
                  promptAsync({ useProxy }).then((value) => {
                    if (value.type === "error") {
                      Alert.alert(
                        "Authentication error",
                        value.params.error_description || t("something went wrong")
                      );
                      return;
                    }
                    if (value.type === "success") {
                      logger.debug("authent successfull");
                      setToken(value.params.id_token);
                    }
                  })
                }
              />
              <View
                style={styles.separator}
                lightColor="#eee"
                darkColor="rgba(255,255,255,0.1)"
              />
              <Button
                title={t("Demo")}
                onPress={() => {
                  logger.debug("demo");
                  setToken(
                    "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlJad3FyQTA3YWcwUlpfT3FpWm1USSJ9.eyJuaWNrbmFtZSI6ImRlbW8ubW9uZXkiLCJuYW1lIjoiZGVtby5tb25leUB0YXNzaW4ud29yayIsInBpY3R1cmUiOiJodHRwczovL3MuZ3JhdmF0YXIuY29tL2F2YXRhci81NjkxODQ5Njc1NDRmZWQ4ODNmODFiMzFhMWJmZjUyNj9zPTQ4MCZyPXBnJmQ9aHR0cHMlM0ElMkYlMkZjZG4uYXV0aDAuY29tJTJGYXZhdGFycyUyRmRlLnBuZyIsInVwZGF0ZWRfYXQiOiIyMDIyLTAyLTAxVDEzOjE4OjI5LjAyNFoiLCJlbWFpbCI6ImRlbW8ubW9uZXlAdGFzc2luLndvcmsiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOi8vanVsaWVudGFzc2luLmV1LmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw2MTRhNDBiNjk5ODEzMzAwNjg2Njk2OWUiLCJhdWQiOiJrWkh2TGdnSlR6eUFvQ2NITGlsV1dEdzNoMHJhNWFHTCIsImlhdCI6MTY0MzcyMjA1MCwiZXhwIjoxNjQzNzU4MDUwLCJub25jZSI6ImpnT1RqLXdjMkU5Y1ZaOXNGZFZkb1QtSGJxNVZPZEoxU3hyVWNhRmVGXzgifQ.A4dE-pFF3zJNUSNNywfIC1zRViQpWBUH9nMOssUStDTEWQHLo3toMKg528UvZw8xuFGtplh4iUP-POgvxDVjHMT07s8280jbHtcSemmpW_Cl32oOsELecGxcPVQ5wbS32ILpUr08D8TBpPpkCoh2NjJrhhw8S_sEuz8VFLzgpHenfz0UfZdw6IUB1bq_r86UAkG9pY8DS7AYfIZHSqQlISrmJc7Fq0B_sRYGwWTcecDejoyTgViGCLnov-x2RJGqAlDvTnEl7oZJxR4ElB2t_rTgHzXTrq9wNWVperO7w7aLSVhICSeC-9HtVC34n-0LYsOdUkrX_FCcCUT7pCwlTA"
                  );
                }}
              />
            </>
          )
        }
      </UserContext.Consumer>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  userName: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: "center",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
